import * as React from 'react';
import styled, { css } from 'styled-components';

const Steps = styled.div<{ $gap: string }>`
  display: flex;
  flex-direction: row;
  align-items: flex-bottom;
  justify-content: space-between;
`;

const StepNumber = styled.div<{ $active?: boolean, $error: boolean, $disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid;
  border-color: ${({ theme, $active, $error, $disabled }) => {
    if ($disabled) return theme.colors.input.placeholder;
    if ($error) return theme.colors.variant.danger;
    if ($active) return theme.colors.global.link;

    return theme.colors.gray[40];
  }};
  color: ${({ theme, $active, $error, $disabled }) => {
    if ($disabled) return theme.colors.input.placeholder;
    if ($error) return theme.colors.variant.danger;
    if ($active) return theme.colors.global.link;

    return theme.colors.gray[40];
  }};
`;

const StepTextColumn = styled.div<{ $active?: boolean, $error: boolean, $disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  color: ${({ theme, $active, $error, $disabled }) => {
    if ($disabled) return theme.colors.input.placeholder;
    if ($error) return theme.colors.variant.danger;
    if ($active) return theme.colors.global.link;

    return theme.colors.gray[40];
  }};
`;

const StepSeparator = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.input.border};
  width: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  align-self: center;
  margin: 0 16px;
`;

const Step = styled.div<{ $disabled: boolean }>`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  
  &:hover,
  &:hover > div {
    ${({ theme, $disabled }) => ($disabled
    ? css`
      cursor: not-allowed;
    ` : css`
      color: ${theme.colors.global.linkHover};
      border-color: ${theme.colors.global.linkHover};
    `)}
  }
`;

export type StepType = {
  id: string;
  title: string;
  valid: boolean;
  disabled?: boolean;
};

type Props = {
  steps: StepType[];
  activeStep: number;
  gap?: string;
  setActiveStep: (stepId: number) => void;
};

function Stepper({ steps, activeStep, gap, setActiveStep }: Props) {
  return (
    <Steps $gap={gap}>
      {steps.map((step, index) => (
        <React.Fragment key={step.id}>
          {index > 0 && <StepSeparator />}
          <Step key={step.id}
                $disabled={step.disabled}
                onClick={() => step.disabled || setActiveStep(index)}>
            <StepNumber $active={index === activeStep}
                        $error={!step.valid}
                        $disabled={step.disabled}>
              {index + 1}
            </StepNumber>
            <StepTextColumn $active={index === activeStep}
                            $error={!step.valid}
                            $disabled={step.disabled}>
              <span>{step.title}</span>
            </StepTextColumn>
          </Step>
        </React.Fragment>
      ))}
    </Steps>
  );
}

Stepper.defaultProps = {
  gap: '8px',
};

export default Stepper;
