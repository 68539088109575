import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts';
import useActivePerspective from 'components/perspectives/hooks/useActivePerspective';

function useIsInvestigationDetailsOnSecurityUI() {
  const { selectedInvestigationId } = useInvestigationDrawer();
  const { activePerspective } = useActivePerspective();
  const isSecurityPerspective = activePerspective.id === 'security';

  return selectedInvestigationId && isSecurityPerspective;
}

export default useIsInvestigationDetailsOnSecurityUI;
