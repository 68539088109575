import * as React from 'react';
import { useEffect, useState, useContext, useCallback } from 'react';
import { OrderedSet } from 'immutable';
import styled, { css } from 'styled-components';

import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import { defaultCompare } from 'logic/DefaultCompare';
import Spinner from 'components/common/Spinner';
import Icon from 'components/common/Icon';
import IconButton from 'components/common/IconButton';
import Button from 'components/bootstrap/Button';
import { MessagesActions } from 'stores/messages/MessagesStore';
import useAppDispatch from 'stores/useAppDispatch';
import { updateWidgetConfig } from 'views/logic/slices/widgetActions';

import MessageDetailsContext from './contexts/MessageDetailsContext';
import LogViewMessageDetailsRow, { LogViewMessageDetailsHeadRow } from './LogViewMessageDetailsRow';

import LogViewWidgetConfig from '../logic/LogViewWidgetConfig';

type Message = {
  decorated_stats: string | undefined | null,
  fields: { [field: string]: any },
  filtered_fields: { [field: string]: any },
  formatted_fields: { [field: string]: any },
  highlight_ranges: { [field: string]: any },
  id: string,
  index: string,
  source_input_id: string,
  source_node_id: string,
  stream_ids: string[],
  timestamp: string,
};

const Container = styled.div(({ theme }) => css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  min-width: 450px;
  background: ${theme.colors.global.contentBackground};
  height: 100%;
  padding: 15px 15px 10px 15px;
  border: 1px solid ${theme.colors.gray[80]};
  z-index: 2;
  display: flex;
  flex-direction: column;
`);

const Table = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  font-size: ${theme.fonts.size.small};
  margin-bottom: 5px;
`);

const TableHead = styled.div(({ theme }) => css`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.gray[90]};
`);

const Header = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FooterElement = styled.div`
  margin: 3px;
`;

const HeaderIcon = styled.div`
  padding: 2px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
`;

const HeaderId = styled.h4(({ theme }) => css`
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: ${theme.fonts.family.monospace};
  overflow: hidden;
  margin-right: 5px;
`);

const _renderRow = (field, value, type, onSelect, selected, editing) => (
  <LogViewMessageDetailsRow key={`${field}-${value}`} field={field} value={value} type={type} onSelect={onSelect} selected={selected} hideCheckbox={editing} />
);

type Props = {
  widgetId: string,
  widgetConfig: LogViewWidgetConfig,
  setLoadingState: (loading: boolean) => void,
  editing: boolean,
}

const LogViewMessageDetails = ({ widgetId, widgetConfig, setLoadingState, editing }: Props) => {
  const [message, setMessage] = useState<Message | undefined | null>();
  const [currentConfig, setCurrentConfig] = useState(widgetConfig);
  const {
    activeMessageDetails,
    selectNextMessage,
    selectPrevMessage,
    setActiveMessageDetailsId,
  } = useContext(MessageDetailsContext);
  const { all } = useContext(FieldTypesContext);
  const columns = currentConfig.fields;
  const onClose = useCallback(() => setActiveMessageDetailsId(undefined), [setActiveMessageDetailsId]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (activeMessageDetails) {
      MessagesActions.loadMessage(activeMessageDetails.index, activeMessageDetails.message._id).then((response: Message) => {
        setMessage(response);
      });
    }
  }, [activeMessageDetails]);

  const closeDetails = useCallback(() => {
    if (!currentConfig.fields.equals(widgetConfig.fields)) {
      setLoadingState(true);

      dispatch(updateWidgetConfig(widgetId, currentConfig)).then(() => {
        setLoadingState(false);
      });
    }

    onClose();
  }, [currentConfig, widgetConfig.fields, onClose, setLoadingState, dispatch, widgetId]);

  const dirty = useCallback(() => !currentConfig.fields.equals(OrderedSet(widgetConfig.fields)),
    [currentConfig.fields, widgetConfig.fields]);

  const onSelect = useCallback((field) => {
    const newFields = columns.includes(field) ? columns.remove(field) : columns.add(field);
    const newConfig = currentConfig.toBuilder().fields(newFields).build();
    setCurrentConfig(newConfig);
  }, [columns, currentConfig]);

  const allSelected = useCallback(() => {
    const fields = Object.keys(message?.formatted_fields);

    return currentConfig.fields.sort().equals(OrderedSet(fields).sort());
  }, [currentConfig.fields, message?.formatted_fields]);

  const onSelectAll = useCallback(() => {
    const fields = Object.keys(message?.formatted_fields);

    if (allSelected()) {
      const newConfig = currentConfig.toBuilder().fields(LogViewWidgetConfig.defaultFields).build();
      setCurrentConfig(newConfig);

      return;
    }

    const allNewConfig = currentConfig.toBuilder().fields(OrderedSet(fields)).build();
    setCurrentConfig(allNewConfig);
  }, [allSelected, currentConfig, message?.formatted_fields]);

  const findFieldType = useCallback((field) => all.find((f) => f.name === field), [all]);

  if (!activeMessageDetails) {
    return null;
  }

  if (!message) {
    return <Spinner delay={300} />;
  }

  const rows = Object.entries(message.formatted_fields).sort(([key1], [key2]) => defaultCompare(key1, key2))
    .map(([field, value]) => _renderRow(field, value, findFieldType(field), () => onSelect(field), columns.includes(field), editing));

  return (
    <Container>
      <Header>
        <HeaderTitle>
          <HeaderIcon><Icon name="mail" /></HeaderIcon>
          <HeaderId title={activeMessageDetails.message._id}>{activeMessageDetails.message._id}</HeaderId>
        </HeaderTitle>
        <div>
          <IconButton disabled={!selectPrevMessage} title="Previous" name="arrow_upward" onClick={selectPrevMessage} />
          <IconButton disabled={!selectNextMessage} title="Next" name="arrow_downward" onClick={selectNextMessage} />
          <IconButton title="Close" name="close" onClick={onClose} />
        </div>
      </Header>
      <Table>
        <TableHead>
          <LogViewMessageDetailsHeadRow onSelectAll={onSelectAll} allSelected={allSelected} hideCheckbox={editing} />
        </TableHead>
        {rows}
      </Table>
      {dirty() && (
        <Footer>
          <FooterElement><Button title="Save & Close" bsSize="small" bsStyle="primary" onClick={closeDetails}>Save & Close</Button></FooterElement>
        </Footer>
      )}
    </Container>
  );
};

export default LogViewMessageDetails;
