import * as React from 'react';
import { useCallback, useState } from 'react';
import union from 'lodash/union';
import { useFormikContext } from 'formik';
import type * as Immutable from 'immutable';

import ParameterDeclarationForm from 'enterprise/parameters/components/ParameterDeclarationForm';
import { Button } from 'components/bootstrap';
import type { QueryValidationState } from 'views/components/searchbar/queryvalidation/types';
import createParametersFromNames from 'enterprise/parameters/components/CreateParametersFromNames';
import type Parameter from 'views/logic/parameters/Parameter';
import useParameterDeclarationContext from 'search-filter/hooks/useParameterDeclarationContext';
import useAppDispatch from 'stores/useAppDispatch';
import { declareParameters } from 'views/logic/slices/searchExecutionSlice';
import useParametersMap from 'views/hooks/useParametersMap';
import useUndeclaredParameters from 'enterprise/parameters/components/useUndeclaredParameters';

type Props = {
  validationState?: QueryValidationState;
};
type ParameterDeclarationContextType = {
  onSaveParametersSuccess?: (params: { toggleDeclarationForm: () => void }) => void
}

const QueryValidationParameterDeclaration = ({ validationState }: Props) => {
  const [showDeclarationForm, setShowDeclarationForm] = useState(false);
  const parameterDeclarationContext = useParameterDeclarationContext<ParameterDeclarationContextType>();
  const existingParameters = useParametersMap();
  const undeclaredParameterNamesFromSearch = useUndeclaredParameters();
  const dispatch = useAppDispatch();
  const { submitForm } = useFormikContext();

  const toggleDeclarationForm = useCallback(() => {
    setShowDeclarationForm((cur) => !cur);
  }, []);

  const parameterValidationErrors = validationState?.explanations?.filter((explanation) => explanation.errorType === 'UNDECLARED_PARAMETER');
  const undeclaredParameterNamesFromValidation = parameterValidationErrors?.flatMap(({ relatedProperty }) => relatedProperty).filter((paramName) => !!paramName);
  const undeclaredParameterNames = union(undeclaredParameterNamesFromSearch.toArray(), undeclaredParameterNamesFromValidation);
  const onSaveParameters = useCallback((parameters: Immutable.Map<string, Parameter>) => dispatch(declareParameters(parameters)).then(() => {
    const onSaveParametersSuccess = parameterDeclarationContext?.onSaveParametersSuccess;

    if (onSaveParametersSuccess) {
      onSaveParametersSuccess({ toggleDeclarationForm });
    } else {
      submitForm();
    }
  }), [dispatch, parameterDeclarationContext?.onSaveParametersSuccess, submitForm, toggleDeclarationForm]);

  if (!undeclaredParameterNames?.length) {
    return null;
  }

  const undeclaredParameters = createParametersFromNames(undeclaredParameterNames);

  return (
    <>
      <Button bsStyle="primary" bsSize="small" onClick={toggleDeclarationForm}>Declare parameters</Button>
      {showDeclarationForm && (
        <ParameterDeclarationForm existingParameters={existingParameters}
                                  parameters={undeclaredParameters}
                                  onClose={toggleDeclarationForm}
                                  onSave={onSaveParameters} />
      )}
    </>
  );
};

QueryValidationParameterDeclaration.defaultProps = {
  validationState: undefined,
};

export default QueryValidationParameterDeclaration;
