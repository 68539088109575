import React, { useContext } from 'react';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';

import type {
  HandleFieldUpdateType,
  FormDataContextType,
  AdvancedOptionsContextType,
} from '../common/utils/types';
import { FormDataContext } from '../common/context/FormData';
import { AdvancedOptionsContext } from '../common/context/AdvancedOptions';
import AdditionalFields from '../common/AdditionalFields';

interface Props {
  onChange: HandleFieldUpdateType;
  selectedContentTypes: string;
}

const StyledAdditionalFields = styled(AdditionalFields)`
    margin: 0 0 35px;
`;

const FormAdvancedOptions: React.FC<Props> = ({ onChange, selectedContentTypes = '' }) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const { isAdvancedOptionsVisible, setAdvancedOptionsVisibility } = useContext<AdvancedOptionsContextType>(AdvancedOptionsContext);

  const {
    symantecSESThrottleEnabled,
    streamConnectionTimeout,
  } = formData;

  const handleToggle: (visible: boolean) => void = (visible) => {
    setAdvancedOptionsVisibility(visible);
  };

  return (
    <StyledAdditionalFields title="Advanced Options"
                            visible={isAdvancedOptionsVisible}
                            onToggle={handleToggle}>

      <Input id="symantecSESThrottleEnabled"
             type="checkbox"
             value="enable-throttling"
             defaultChecked={symantecSESThrottleEnabled?.value}
             onChange={onChange}
             label="Enable Throttling"
             help="If enabled, no new messages will be read from this input until Graylog catches up with its message load. This is typically useful for inputs reading from files or message queue systems like AMQP or Kafka. If you regularly poll an external system, e.g. via HTTP, you normally want to leave this disabled." />
      {(selectedContentTypes === 'events' || selectedContentTypes === 'events,incidents' || selectedContentTypes === 'incidents,events') && (

      <Input id="streamConnectionTimeout"
             name="streamConnectionTimeout"
             type="number"
             value={streamConnectionTimeout.value || streamConnectionTimeout.defaultValue || ''}
             min="1"
             max="60"
             step="1"
             onChange={onChange}
             required
             help="Event stream connection timeout in minutes, indicates how long the stream connection should be available."
             label="Event Stream Connection Timeout" />
      )}
    </StyledAdditionalFields>
  );
};

export default FormAdvancedOptions;
