import { Map } from 'immutable';

import Widget from 'views/logic/widgets/Widget';
import type { WidgetState } from 'views/logic/widgets/Widget';
import isDeepEqual from 'stores/isDeepEqual';
import isEqualForSearch from 'views/stores/isEqualForSearch';

import InvestigationsWidgetConfig from './InvestigationsWidgetConfig';

export default class InvestigationsWidget extends Widget {
  constructor(id: string, config: InvestigationsWidgetConfig) {
    super(id, InvestigationsWidget.type, config, null, null, null, [], []);
  }

  static type = 'investigations';

  static defaultTitle = 'Untitled Investigations Overview';

  // eslint-disable-next-line class-methods-use-this
  get returnsAllRecords() {
    return true;
  }

  static fromJSON(value: WidgetState) {
    const { id, config } = value;

    return new InvestigationsWidget(id, InvestigationsWidgetConfig.fromJSON(config));
  }

  equals(other: any) {
    if (other instanceof InvestigationsWidget) {
      return ['id', 'config'].every((key) => isDeepEqual(this._value[key], other[key]));
    }

    return false;
  }

  equalsForSearch(other: any) {
    if (other instanceof InvestigationsWidget) {
      return ['id', 'config'].every((key) => isEqualForSearch(this._value[key], other[key]));
    }

    return false;
  }

  toBuilder() {
    const { id, config } = this._value;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder(Map({ id, config }));
  }

  static builder() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder();
  }
}

class Builder extends Widget.Builder {
  build(): InvestigationsWidget {
    const { id, config } = this.value.toObject();

    return new InvestigationsWidget(id, config);
  }
}
