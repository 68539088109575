import * as React from 'react';
import styled from 'styled-components';

import IconButton from 'components/common/IconButton';
import type { Message } from 'views/components/messagelist/Types';
import MessageField from 'views/components/messagelist/MessageField';
import CustomHighlighting from 'views/components/highlighting/CustomHighlighting';
import type FieldType from 'views/logic/fieldtypes/FieldType';
import HoverForHelp from 'components/common/HoverForHelp';
import { isArrayValue } from 'search/messageFieldValueHelper';

const Container = styled.div(({ theme }) => `
  background-color: ${theme.colors.table.variant.danger};
  display: flex;
  padding: ${theme.spacings.xxs};
  border-radius: 3px;

  &:not(last-child) {
    margin-bottom: ${theme.spacings.xxs};
  }
`);

const FieldsCol = styled.div(({ theme }) => `
  padding: 2px;
  color: black;
  flex: 1;
  color: ${theme.colors.gray[30]};
`);

const ActionsCol = styled.div`
  padding: 2px;
`;

const StyledHoverForHelp = styled((props) => <HoverForHelp {...props} />)`
  margin: 3px 8px;
`;

type Props = {
  allowRemoval: boolean
  title: string,
  fieldName: string,
  fieldValue: unknown,
  realFieldType: FieldType,
  message: Message,
  onDelete: () => void,
};

const WatchlistIndicator = ({ allowRemoval, title, fieldName, fieldValue, realFieldType, onDelete, message }: Props) => {
  const isArrayFieldValue = isArrayValue(fieldValue);

  return (
    <Container title={title}>
      <FieldsCol>
        <CustomHighlighting key={fieldName}
                            field={fieldName}
                            value={fieldValue}>
          <MessageField fieldName={fieldName}
                        fieldType={realFieldType}
                        message={message}
                        value={fieldValue} />
        </CustomHighlighting>
      </FieldsCol>
      {(allowRemoval && !isArrayFieldValue) && (
        <ActionsCol>
          <IconButton name="delete" onClick={onDelete} title={`Remove from ${title}`} />
        </ActionsCol>
      )}
      {(allowRemoval && isArrayFieldValue) && (
        <StyledHoverForHelp title="Deletion not possible">
          This field value is an array, at least one value is on the {title}.
          Removing multiple values from a watchlist is currently not supported.
          If you want to remove the values you can manually edit the watchlist lookup table.
        </StyledHoverForHelp>
      )}
    </Container>
  );
};

export default WatchlistIndicator;
