import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';
import { Input } from 'components/bootstrap';

import type { FormFieldDataType, HandleFieldUpdateType } from '../types';

type LabelProps = { label: string; error: string };

const Error = styled.span`
  display: block;
  font-weight: normal;
  padding-left: 15px;
  font-size: 0.85em;
`;

const ErrorContainer = styled.span`
  display: flex;
  align-items: center;
`;

const Label: React.FC<LabelProps> = ({ label, error }) => {
  if (error) {
    return (
      <ErrorContainer>
        {label}
        <Error>
          <Icon name="warning" />
          {error}
        </Error>
      </ErrorContainer>
    );
  }

  return <> {label}</>;
};

interface ValidatedInputProps {
  className?: string;
  fieldData?: FormFieldDataType;
  help?: string;
  label: string;
  id: string;
  onChange?: HandleFieldUpdateType;
  required?: boolean;
  type: string;
  placeholder?: string;
  autoComplete?: string;
  defaultValue?: any;
}

const ValidatedInput = ({
  id,
  label,
  type,
  className = undefined,
  onChange = () => {},
  help = '',
  fieldData = {
    dirty: false,
    error: undefined,
    value: undefined,
  },
  ...restProps
}: React.PropsWithChildren<ValidatedInputProps>) => {
  const { dirty, error, value } = fieldData;

  const checkValidity = (event: React.FocusEvent<HTMLInputElement>) => {
    if (dirty) {
      const errorOutput = '';
      onChange(event, { error: errorOutput });
    }
  };

  return (
    <Input {...restProps}
           id={id}
           type={type}
           onChange={onChange}
           onBlur={checkValidity}
           className={className}
           bsStyle={(error && dirty && 'error') || undefined}
           defaultValue={(type !== 'select' && value) || undefined}
           value={(type === 'select' && value) || undefined}
           label={<Label label={label} error={error} />}
           help={help} />
  );
};

ValidatedInput.defaultProps = {
  className: undefined,
  onChange: () => {},
  help: '',
  fieldData: {
    dirty: false,
    error: undefined,
    value: undefined,
  },
  required: undefined,
  placeholder: undefined,
  autoComplete: undefined,
  defaultValue: undefined,
};

export default ValidatedInput;
