// eslint-disable-next-line no-unused-vars, import/default
// We need to set the app prefix before doing anything else, so it applies to styles too.
import './webpack-entry';

import Immutable from 'immutable';
import { PluginManifest, PluginStore } from 'graylog-web-plugin/plugin';

import archiveBindings from 'archive/bindings';
import auditlogBindings from 'auditlog/bindings';
import customizationBindings from 'customization/bindings';
import dataTieringBindings from 'data-tiering/bindings';
import failureBindings from 'failure/bindings';
import forwarderBindings from 'forwarder/bindings';
import illuminateBindings from 'illuminate/bindings';
import licenseBindings from 'license/bindings';
import supportBundleBindings from 'support-bundle/binding';
import reportBindings from 'report/bindings';
import lookupBindings from 'lookup/bindings';
import logViewBindings from 'logview/bindings';
import securityAppBindings from 'security-app/bindings';
import securityBindings from 'security/bindings';
import externalValueActionsBindings from 'security-content/bindings.externalValueActions';
import authenticationBindings from 'authentication/bindings';
import parameterBindings from 'enterprise/parameters/ParameterBindings';
import eventCorrelationBindings from 'event-correlation/bindings';
import eventSigmaBindings from 'event-sigma/bindings';
import trafficViolationBindings from 'traffic-violation/bindings';
import indexTimeArchivingBindings from 'instant-archiving/bindings';

import searchFilterBindings from './search-filter/bindings';
import watchlistBindings from './watchlist/bindings';
import enterpriseMenuBindings from './EnterpriseMenuBindings';
import exportBindings from './search/exportBindings';
import searchBindings from './search/bindings';
import cloudComponentBindings from './cloud/components/bindings';
import oidcAuthBindings from './cloud/oidc-authentication/bindings';
import enterpriseIntegrationsExport from './enterprise-integrations/bindings';
import datanodeBindings from './datanode/bindings';

import packageJson from '../../package.json';

const merger = (oldVal, newVal) => oldVal.concat(newVal);
const bindings = Immutable.Map().mergeWith(
  merger,
  archiveBindings,
  auditlogBindings,
  authenticationBindings,
  customizationBindings,
  dataTieringBindings,
  enterpriseMenuBindings,
  eventCorrelationBindings,
  eventSigmaBindings,
  exportBindings,
  externalValueActionsBindings,
  failureBindings,
  forwarderBindings,
  illuminateBindings,
  licenseBindings,
  supportBundleBindings,
  logViewBindings,
  lookupBindings,
  searchBindings,
  parameterBindings,
  reportBindings,
  searchFilterBindings,
  securityBindings,
  securityAppBindings,
  trafficViolationBindings,
  watchlistBindings,
  cloudComponentBindings,
  oidcAuthBindings,
  indexTimeArchivingBindings,
  enterpriseIntegrationsExport,
  datanodeBindings,
);
const manifest = new PluginManifest(packageJson, bindings.toJS());

PluginStore.register(manifest);
