import * as React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/common';
import useIsInvestigationDetailsOnSecurityUI from 'security-app/components/Investigations/plugin/shared/useIsInvestigationDetailsOnSecurityUI';
import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts';
import { useAddEvidence, useGetInvestigationDetails } from 'security-app/hooks/useInvestigationsAPI';

import AddEvidenceModal from './shared/AddEvidenceModal';

const EvidenceActionButton = styled.a<{ $disabled?: boolean }>(({ $disabled, theme }) => css`
  display: flex;
  align-items: center;
  color: ${$disabled ? theme.colors.gray[90] : 'inherit'};
  text-decoration: none;
  gap: 6px;
  cursor: ${$disabled ? 'not-allowed' : 'pointer'};

  &:hover {
    color: ${$disabled ? theme.colors.gray[90] : 'default'};
  }

  &:visited {
    color: inherit;
  }
`);

const InvestigationsEventAction = ({ eventId }: { eventId: string }) => {
  const addEvidenceModalRef = React.useRef(null);

  const { selectedInvestigationId, setCollapsed } = useInvestigationDrawer();
  const { currentInvestigation } = useGetInvestigationDetails(selectedInvestigationId);

  const hideSelectAnInvestigation = useIsInvestigationDetailsOnSecurityUI();

  const { addEvidence } = useAddEvidence();

  const disabledAdd = !currentInvestigation || currentInvestigation?.archived;

  const onAddEvent = async () => {
    if (disabledAdd) return;

    await addEvidence({
      invId: selectedInvestigationId,
      payload: { events: [eventId] },
    });

    setCollapsed(false);
  };

  return (
    <>
      <dd>
        <EvidenceActionButton onClick={onAddEvent} $disabled={disabledAdd}>
          Add to investigation <Icon name="extension" size="sm" />
        </EvidenceActionButton>
      </dd>
      {hideSelectAnInvestigation || (
        <dd>
          <EvidenceActionButton onClick={() => addEvidenceModalRef.current.toggle()}>
            Select an investigation <Icon name="extension" size="sm" />
          </EvidenceActionButton>
        </dd>
      )}
      <AddEvidenceModal id={eventId} type="events" ref={addEvidenceModalRef} />
    </>
  );
};

export default InvestigationsEventAction;
