import * as React from 'react';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import StringUtils from 'util/StringUtils';
import UserNotification from 'util/UserNotification';
import BulkActionsDropdown from 'components/common/EntityDataTable/BulkActionsDropdown';
import DeleteArchiveAction from 'instant-archiving/InstantArchivingOverview/BulkActions/DeleteArchiveAction';
import RestoreArchiveAction from 'instant-archiving/InstantArchivingOverview/BulkActions/RestoreArchiveAction';
import ArchiverStatusAction from 'instant-archiving/InstantArchivingOverview/BulkActions/ArchiverStatusAction';
import useSelectedEntities from 'components/common/EntityDataTable/hooks/useSelectedEntities';

const BulkActions = () => {
  const { selectedEntities, setSelectedEntities } = useSelectedEntities();
  const queryClient = useQueryClient();
  const selectedItemsAmount = selectedEntities?.length;
  const descriptor = StringUtils.pluralize(selectedItemsAmount, 'archive', 'archives');
  const refetchArchives = useCallback(() => queryClient.invalidateQueries(['instant-archiving', 'overview']), [queryClient]);

  const handleFailures = useCallback((failures: Array<{ entity_id: string }>, actionPastTense: string) => {
    if (failures?.length) {
      const notDeletedArchiveIds = failures.map(({ entity_id }) => entity_id);
      setSelectedEntities(notDeletedArchiveIds);
      UserNotification.error(`${notDeletedArchiveIds.length} out of ${selectedItemsAmount} selected ${descriptor} could not be ${actionPastTense}.`);
    } else {
      setSelectedEntities([]);
      UserNotification.success(`${selectedItemsAmount} ${descriptor} ${StringUtils.pluralize(selectedItemsAmount, 'was', 'were')} ${actionPastTense} successfully.`, 'Success');
    }
  }, [descriptor, selectedItemsAmount, setSelectedEntities]);

  return (
    <BulkActionsDropdown>
      <DeleteArchiveAction handleFailures={handleFailures}
                           selectedArchiveIds={selectedEntities}
                           refetchArchives={refetchArchives}
                           descriptor={descriptor} />
      <RestoreArchiveAction handleFailures={handleFailures}
                            selectedArchiveIds={selectedEntities}
                            refetchArchives={refetchArchives}
                            descriptor={descriptor} />
      <ArchiverStatusAction descriptor={StringUtils.pluralize(selectedItemsAmount, 'archive', 'archives')}
                            refetchArchives={refetchArchives} />
    </BulkActionsDropdown>
  );
};

export default BulkActions;
