import * as React from 'react';
import { useState, useCallback } from 'react';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { INSTANT_ARCHIVING_API_ROUTES } from 'instant-archiving/Constants';
import UserNotification from 'util/UserNotification';
import { Button, ButtonToolbar } from 'components/bootstrap';
import type {
  InstantArchivingActionFormValues,
} from 'instant-archiving/components/InstantArchivingActionModalForm';
import InstantArchivingActionModalForm from 'instant-archiving/components/InstantArchivingActionModalForm';
import useUserDateTime from 'hooks/useUserDateTime';
import { getInstantArchivingUriParams } from 'instant-archiving/logic/Utils';

type Props = {
  descriptor: string,
  handleFailures: (failures: Array<{
    entity_id: string
  }>, actionPastTense: string, archiveId: string) => void,
  onSelect?: () => void,
  refetchArchives: () => void,
  selectedArchiveIds: Array<string>,
  buttonType?: 'menu' | 'button',
  onDelete?: () => void
};

const DeleteArchiveAction = ({
  selectedArchiveIds,
  handleFailures,
  refetchArchives,
  descriptor,
  onSelect,
  buttonType,
  onDelete,
}: Props) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { userTimezone } = useUserDateTime();
  const onDeleteSubmit = useCallback((values: InstantArchivingActionFormValues) => {
    const uriParams = getInstantArchivingUriParams(values, userTimezone);

    fetch(
      'POST',
      qualifyUrl(`${INSTANT_ARCHIVING_API_ROUTES.InstantArchivingAPIController.bulk_delete().url}?${uriParams}`),
      { archives: [...selectedArchiveIds] },
    ).then((response) => {
      const failures = response?.failures || [];
      handleFailures(failures, 'submitted', selectedArchiveIds[0]);
    }).catch((error) => {
      UserNotification.error(`An error occurred while deleting archive. ${error}`);
    }).finally(() => {
      if (typeof onDelete === 'function') {
        onDelete();
      }

      setShowConfirmDelete(false);
      refetchArchives();
    });
  }, [userTimezone, selectedArchiveIds, handleFailures, onDelete, refetchArchives]);

  const onOpenDeleteConfirm = () => {
    if (typeof onSelect === 'function') {
      onSelect();
    }

    setShowConfirmDelete(true);
  };

  const isMenuButton = buttonType === 'menu';

  return (
    <ButtonToolbar className="pull-right">
      <Button bsStyle={isMenuButton ? 'default' : 'success'}
              bsSize={isMenuButton ? 'xsmall' : 'medium'}
              onClick={() => onOpenDeleteConfirm()}>Delete
      </Button>
      {showConfirmDelete && (
        <InstantArchivingActionModalForm modalTitle="Delete Archives! Are you sure?"
                                         archiveId={(selectedArchiveIds?.length === 1) && selectedArchiveIds[0]}
                                         show={showConfirmDelete}
                                         type="delete"
                                         onHide={() => setShowConfirmDelete(false)}
                                         submitButtonText="Delete"
                                         hourOnly
                                         onSubmit={onDeleteSubmit}>
          <p>{`You are about to remove ${selectedArchiveIds?.length} ${descriptor}. This action cannot be undone.`}</p>
        </InstantArchivingActionModalForm>
      )}
    </ButtonToolbar>
  );
};

DeleteArchiveAction.defaultProps = {
  onSelect: undefined,
  buttonType: 'menu',
  onDelete: undefined,
};

export default DeleteArchiveAction;
