import React, { useState } from 'react';
import type { SyntheticEvent } from 'react';
import { Field } from 'formik';

import { Alert, Button, Col, Input, Row } from 'components/bootstrap';
import { ConfirmDialog, Select, Spinner } from 'components/common';

import useRepositories from './repository-management/hooks/useRepositories';
import RepositoryOption from './repository-management/RepositoryOption';
import CreateRepositoryModal from './repository-management/CreateRepositoryModal';

type Option = {value: string, label: string, ready: boolean, type: string, description?: string}

const RepositoryManagement = () => {
  const [showCreationModal, setShowCreationModal] = useState<boolean>(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<boolean>(false);
  const [repoToDelete, setRepoToDelete] = useState<string|undefined>(undefined);
  const { onDeleteRepository, repositories } = useRepositories();

  const buildOptions = () : Option[] => repositories.data.map((repo) => ({
    value: repo.repository.name,
    label: repo.repository.name,
    ready: repo.ready,
    type: repo.repository.type,
    description: repo.details,
  }));

  const handleDelete = (event: SyntheticEvent, value: string) => {
    event.stopPropagation();
    setRepoToDelete(value);
    setShowConfirmDeleteDialog(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmDeleteDialog(false);
    setRepoToDelete(undefined);
  };

  const onDelete = () => {
    onDeleteRepository(repoToDelete);
    setShowConfirmDeleteDialog(false);
    setRepoToDelete(undefined);
  };

  const optionRenderer = (option: Option, isSelected: boolean) => (
    <RepositoryOption repository={option} onDelete={handleDelete} isSelected={isSelected} />
  );

  const repositorySelect = (name, value, onChange, onBlur) => {
    if (repositories.isLoading) return <Spinner>Fetching warm storage repositories...</Spinner>;
    if (repositories.data?.length === 0) return <Alert bsStyle="info">No warm storage repositories existing, please create one.</Alert>;

    return (
      <Select placeholder="Select an existing warm storage repository"
              options={buildOptions()}
              optionRenderer={optionRenderer}
              autoFocus
              required
              onBlur={onBlur}
              onChange={(selected) => onChange({ target: { value: selected, name } })}
              value={value} />
    );
  };

  return (
    <>
      <Field name="data_tiering.warm_tier_repository_name">
        {({ field: { name, value, onChange, onBlur }, meta: { error } }) => (
          <Input bsStyle={error ? 'error' : undefined}
                 help="Which warm storage repository should this index set be stored in"
                 error=""
                 id="default-roles-select"
                 label="Repository"
                 labelClassName="col-sm-3"
                 wrapperClassName="col-sm-9">
            <Row>
              <Col md={8}>
                {repositorySelect(name, value, onChange, onBlur)}
              </Col>
              <Col md={4} className="text-right">
                <Button onClick={() => setShowCreationModal(true)}>
                  Create new warm storage repository
                </Button>
              </Col>
            </Row>
          </Input>
        )}
      </Field>
      <CreateRepositoryModal show={showCreationModal} hideModal={() => setShowCreationModal(false)} />
      {showConfirmDeleteDialog && (
      <ConfirmDialog show={showConfirmDeleteDialog}
                     onConfirm={onDelete}
                     onCancel={handleCancelDelete}
                     title="Delete warm storage repository">
        <>
          <p>
            You are about to delete the warm storage repository <strong>{repoToDelete}</strong>.
            This action cannot be undone.
          </p>
          <p>Are you sure you want to continue?</p>
        </>
      </ConfirmDialog>
      )}
    </>
  );
};

export default RepositoryManagement;
