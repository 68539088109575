import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import type { Status } from 'license/types';
import type FetchError from 'logic/errors/FetchError';

const urlPrefix = '/plugins/org.graylog.plugins.license/licenses/status';
export const LICENSES_STATUS_KEY = (subject: string) => `licenses_status_${subject}`;

const getLicenseStatusForSubject = (subject: string) => fetch(
  'GET',
  qualifyUrl(`${urlPrefix}/for-subject?subject=${subject}`),
);

const useLicenseStatusForSubject = (subject: string) => useQuery<{status: Status}, FetchError>([LICENSES_STATUS_KEY(subject)], () => getLicenseStatusForSubject(subject), {
  onError: (errorThrown) => {
    if (!(errorThrown.status === 404)) {
      UserNotification.error(`Loading license status for subject failed with: ${errorThrown}`);
    }
  },
  retry: 0,
});

export default useLicenseStatusForSubject;
