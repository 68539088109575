import Reflux from 'reflux';

import type { LicenseStatus } from 'archive/types';

export type ActionsType = {
  getLicenseStatus: () => Promise<LicenseStatus>,
};

const ArchiveLicenseActions = Reflux.createActions<ActionsType>({
  getLicenseStatus: { asyncResult: true },
});

export default ArchiveLicenseActions;
