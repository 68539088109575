import React from 'react';

import type { Input } from 'components/messageloaders/Types';
import AppConfig from 'util/AppConfig';
import { Alert } from 'components/bootstrap';
import { Link } from 'components/common/router';
import Routes from 'routing/Routes';

export type ForwarderInputInformationProps = {
    forwarderInputs?: Array<Input>;
};

const ForwarderInputInformation = ({ forwarderInputs }: ForwarderInputInformationProps) => {
  if (AppConfig.isCloud()) {
    return null;
  }

  if (forwarderInputs.length === 0) {
    return (
      <Alert bsStyle="warning">
        No Forwarder Input found, please create one on the <Link to={Routes.SYSTEM.INPUTS}>Inputs page</Link> and try again.
      </Alert>
    );
  }

  return (
    <Alert bsStyle="info">
      Using Input <em>{forwarderInputs[0].title}</em> to provide configuration options.
    </Alert>
  );
};

ForwarderInputInformation.defaultProps = {
  forwarderInputs: [],
};

export default ForwarderInputInformation;
