import type { AppDispatch } from 'stores/useAppDispatch';
import { addWidget } from 'views/logic/slices/widgetActions';

import InvestigationsWidgetConfig from './InvestigationsWidgetConfig';
import InvestigationsWidget from './InvestigationsWidget';

export const CreateInvestigationsWidget = () => InvestigationsWidget.builder()
  .newId()
  .config(InvestigationsWidgetConfig.createDefault())
  .build();

export default () => async (dispatch: AppDispatch) => dispatch(addWidget(CreateInvestigationsWidget()));
