import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import useCurrentUser from 'hooks/useCurrentUser';
import Routes from 'routing/Routes';
import { isPermitted } from 'util/PermissionsMixin';
import useHistory from 'routing/useHistory';

type RequiredPermissionsFunction<P> = (props: P) => string[];
type ComponentType<P> = React.ComponentType<P>;

const isRequiredPermissionFunction = <P, >(f: string[] | RequiredPermissionsFunction<P>): f is RequiredPermissionsFunction<P> => isFunction(f);

const withIsPermitted = <Props extends object>(
  Component: ComponentType<Props>,
  requiredPermissions: string[] | RequiredPermissionsFunction<Props>,
): React.ComponentType<Props> => (props: Props) => {
    const currentUser = useCurrentUser();
    const history = useHistory();

    const effectiveRequiredPermissions = isRequiredPermissionFunction(requiredPermissions)
      ? requiredPermissions(props)
      : requiredPermissions;
    const hasPermissions = isPermitted(currentUser?.permissions, effectiveRequiredPermissions);

    useEffect(() => {
      if (currentUser && !hasPermissions) {
        history.push(Routes.NOTFOUND);
      }
    }, [currentUser, currentUser.permissions, effectiveRequiredPermissions, hasPermissions, history]);

    return hasPermissions ? <Component {...props} /> : null;
  };

withIsPermitted.propTypes = {
  requiredPermissions: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  Component: PropTypes.element,
};

export default withIsPermitted;
