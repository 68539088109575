import * as React from 'react';
import { useState } from 'react';
import { Form, Formik, FieldArray, Field } from 'formik';
import styled, { css } from 'styled-components';
import uniq from 'lodash/uniq';

import { IfPermitted, Icon, ModalSubmit } from 'components/common';
import { Button, Input, Modal, Table } from 'components/bootstrap';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';

import type { TrafficLimitViolationConfigType } from './TrafficLimitViolationConfigStore';

const LabelSpan = styled.span(({ theme }) => css`
  margin-left: ${theme.spacings.sm};
  font-weight: bold;
`);
const StyledTable = styled(Table)`
  margin-top: 10px;
`;

type Props = {
  saveConfig: (config: TrafficLimitViolationConfigType) => void,
  recipients: Array<string>,
}

const TrafficLimitViolationConfigForm = ({ saveConfig, recipients }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const sendTelemetry = useSendTelemetry();

  const _resetConfig = () => {
    setShowModal(false);
  };

  const _saveConfig = (config) => {
    const updatedConfig = { email_recipients: uniq(config.email_recipients) };
    saveConfig(updatedConfig);

    sendTelemetry(TELEMETRY_EVENT_TYPE.TRAFFIC_VIOLATION_CONFIGURATION_SAVED, {
      app_pathname: 'configurations',
      app_section: 'traffic-limi-violation',
    });

    setShowModal(false);
  };

  const modalTitle = 'Configure Traffic Limit Violation';

  return (
    <>
      <IfPermitted permissions="clusterconfigentry:edit">
        <Button type="button"
                bsSize="xs"
                bsStyle="info"
                onClick={() => {
                  setShowModal(true);
                }}>
          Edit configuration
        </Button>
      </IfPermitted>
      <Modal show={showModal}
             onHide={_resetConfig}
             aria-modal="true"
             aria-labelledby="dialog_label">
        <Formik onSubmit={_saveConfig} initialValues={{ email_recipients: recipients }}>

          {({ isSubmitting, values }) => (
            (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title id="dialog_label">{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FieldArray name="email_recipients"
                              render={(arrayHelpers) => (
                                <>
                                  <StyledTable striped bordered>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Email</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {values.email_recipients && values.email_recipients.map((email, idx) => (
                                      // eslint-disable-next-line react/no-array-index-key
                                        <tr key={idx}>
                                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{idx + 1}</td>
                                          <td>
                                            <Field name={`email_recipients[${idx}]`}
                                                   value={email}>
                                              {({ field, meta }) => (
                                                <>
                                                  <Input id={`email-${idx}`}
                                                         name={field.name}
                                                         value={field.value}
                                                         onChange={field.onChange}
                                                         bsStyle={meta.touched && meta.error ? 'danger' : null}
                                                         onBlur={field.onBlur}
                                                         type="email"
                                                         required />
                                                  {meta.touched && meta.error && (
                                                  <LabelSpan>{`${field.value} is not a valid email.`}</LabelSpan>)}
                                                </>
                                              )}
                                            </Field>
                                          </td>
                                          <td>
                                            <Button bsStyle="danger" onClick={() => arrayHelpers.remove(idx)}>
                                              <Icon name="delete" />
                                            </Button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </StyledTable>
                                  <Button type="button" onClick={() => arrayHelpers.push('')}>Add Recipient</Button>
                                </>
                              )} />
                </Modal.Body>
                <Modal.Footer>
                  <ModalSubmit onCancel={_resetConfig}
                               isSubmitting={isSubmitting}
                               isAsyncSubmit
                               submitButtonText="Update configuration"
                               submitLoadingText="Updating configuration..." />
                </Modal.Footer>
              </Form>
            )
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default TrafficLimitViolationConfigForm;
