import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useStore } from 'stores/connect';
import Button from 'components/bootstrap/Button';
import Icon from 'components/common/Icon';

import UploadFieldStore, { UploadFieldActions } from './UploadField/UploadFieldStore';
import type { UploadFile } from './UploadField/Types';

type UploadCSVFileAdapterSummaryProps = {
  dataAdapter: {
    config: {
      file_id: string;
      separator: string;
      quotechar: string;
      key_column: string;
      value_column: string;
      check_interval: number;
      case_insensitive_lookup: boolean;
    },
  },
};

const DownloadButton = styled(Button)`
  padding: 0;
  border: 0;
`;

const UploadCSVFileAdapterSummary = ({ dataAdapter }: UploadCSVFileAdapterSummaryProps) => {
  const { config } = dataAdapter;
  const [isLoading, setIsLoading] = useState(true);
  const [currentFile, setCurrentFile] = useState<UploadFile | typeof undefined>();
  useStore(UploadFieldStore);

  const fileId = config.file_id;

  const _downloadFile = () => UploadFieldActions.downloadFile(fileId);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    UploadFieldActions.get(fileId)
      .then((response) => {
        setCurrentFile({
          id: fileId,
          name: response.file_name,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fileId, isLoading]);

  return (
    <dl>
      <dt>File name</dt>
      {!currentFile && <dd>Loading...</dd>}
      {currentFile && (
        <dd>
          <DownloadButton bsStyle="link" bsSize="small" onClick={_downloadFile}>
            {currentFile.name}
            <Icon name="download" style={{ marginLeft: 4 }} />
          </DownloadButton>
        </dd>
      )}
      <dt>Separator</dt>
      <dd><code>{config.separator}</code></dd>
      <dt>Quote character</dt>
      <dd><code>{config.quotechar}</code></dd>
      <dt>Key column</dt>
      <dd>{config.key_column}</dd>
      <dt>Value column</dt>
      <dd>{config.value_column}</dd>
      <dt>Check interval</dt>
      <dd>{config.check_interval} seconds</dd>
      <dt>Case-insensitive lookup</dt>
      <dd>{config.case_insensitive_lookup ? 'yes' : 'no'}</dd>
    </dl>
  );
};

UploadCSVFileAdapterSummary.propTypes = {
  dataAdapter: PropTypes.object.isRequired,
};

export default UploadCSVFileAdapterSummary;
