import * as React from 'react';

import { Link } from 'components/common/router';
import { Alert } from 'components/bootstrap';
import Routes from 'routing/Routes';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';

type Props = {
  onDismissNotification: () => void
};

const LicenseTrafficViolationNotification = ({ onDismissNotification }: Props) => {
  const currentUser = useCurrentUser();

  return (
    <Alert bsStyle="danger" className="has-bm" onDismiss={() => onDismissNotification()} title="Graylog License Violation Warning">
      <p>
        Your current traffic has exceeded your Graylog license traffic limitation.
        If traffic repeatedly exceeds the limit of the license, some features will be turned off.{' '}
        {isPermitted(currentUser?.permissions, 'licenses:create') ? (
          <>
            Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for
            more information or contact your Graylog account manager.
          </>
        ) : (
          <>Contact your Graylog account manager for more information.</>
        )}
      </p>
    </Alert>
  );
};

export default LicenseTrafficViolationNotification;
