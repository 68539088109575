import React from 'react';

import PageHeader from 'components/common/PageHeader';

import EmbeddedSymantecSESApp from './EmbeddedSymantecSESApp';

const SymantecSESApp: React.FC = () => (
  <>
    <PageHeader title="Symantec SES Integrations">
      <span>This feature retrieves events and incidents from Symantec SES platform.</span>
    </PageHeader>
    <EmbeddedSymantecSESApp />
  </>
);

export default SymantecSESApp;
