import React from 'react';
import styled from 'styled-components';

import type { WidgetComponentProps } from 'views/types';
import AutoFontSizer from 'views/components/visualizations/number/AutoFontSizer';
import { ElementDimensions } from 'components/common';

import type InvestigationsListConfig from './logic/InvestigationsWidgetConfig';
import type { InvestigationsListResult } from './types';

const NumberBox = styled(ElementDimensions)`
  height: 100%;
  width: 100%;
  padding-bottom: 10px;
`;

const InvestigationsNumber = ({ data } : WidgetComponentProps<InvestigationsListConfig, InvestigationsListResult>) => (
  <NumberBox resizeDelay={20}>
    {({ height, width }) => (
      <AutoFontSizer height={height} width={width} center>
        <div>
          {data.totalResults}
        </div>
      </AutoFontSizer>
    )}
  </NumberBox>
);

export default InvestigationsNumber;
