import Direction from 'views/logic/aggregationbuilder/Direction';

type InvestigationsListSortConfigJson = {
  field: string,
  direction: 'ASC' | 'DESC',
};

type InternalState = {
  field: string,
  direction: Direction,
};

export default class InvestigationsListSortConfig {
  _value: InternalState;

  constructor(field: string, direction: Direction) {
    this._value = { field, direction };
  }

  toJSON(): InvestigationsListSortConfigJson {
    const { field, direction } = this._value;

    return {
      field,
      direction: direction === Direction.Ascending ? 'ASC' : 'DESC',
    };
  }

  static fromJSON({ field, direction }: InvestigationsListSortConfigJson) {
    const directionJSON = Direction.fromJSON(direction === 'ASC' ? 'Ascending' : 'Descending');

    return new InvestigationsListSortConfig(field, directionJSON);
  }
}
