import * as React from 'react';

import { ConfirmDialog } from 'security-app/components/common/Modal';
import { useModalContext } from 'security-app/components/common/Modal/ModalContext';
import { useTriggerSecurityEventNotification } from 'security-app/hooks/useSecurityEventsAPI';
import { PaginationProvider } from 'security-app/components/common/contexts';

import List from './List';

function SendNotification() {
  const { entity: securityEvent, setEntity, setModal } = useModalContext();
  const [notificationId, setNotificationId] = React.useState<string>('');
  const { triggerSecurityEventNotification, triggeringSecurityEventNotification } = useTriggerSecurityEventNotification();

  const closeModal = () => {
    setModal(null);
    setEntity(null);
  };

  const onConfirm = async () => {
    await triggerSecurityEventNotification({ id: securityEvent.event_id, notificationId: notificationId });

    setModal(null);
    setEntity(null);
  };

  return (
    <ConfirmDialog show
                   onCancel={closeModal}
                   onConfirm={onConfirm}
                   confirmDisabled={triggeringSecurityEventNotification}
                   title="Send Notification">
      <PaginationProvider>
        <List notificationId={notificationId} setNotificationId={setNotificationId} />
      </PaginationProvider>
    </ConfirmDialog>
  );
}

export default SendNotification;
