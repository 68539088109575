import * as React from 'react';
import styled from 'styled-components';

import Routes from 'security-app/routing/Routes';
import { Header, NavRow, NavTabLink, SectionHeading } from 'security-app/components/Layout';
import useSearchVersionCheck from 'hooks/useSearchVersionCheck';
import { Spinner } from 'components/common';
import OpenSearchRequiredPage from 'security-app/pages/OpenSearchRequiredPage';
import StyledPageContentLayout from 'security-app/components/common/StyledPageContentLayout';
import PageContentLayout from 'components/layout/PageContentLayout';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;
`;

type AnomalyLayoutProps = {
  children: React.ReactNode;
};

export default function AnomalyLayout({ children }: AnomalyLayoutProps) {
  const { data: usingOpenSearch, isLoading, error } = useSearchVersionCheck('opensearch');
  if (isLoading) return <Spinner />;
  if (error) return <div>Error: {error.message}</div>;

  if (!usingOpenSearch.satisfied) {
    return (
      <StyledPageContentLayout>
        <OpenSearchRequiredPage />
      </StyledPageContentLayout>
    );
  }

  return (
    <PageContentLayout>
      <Main>
        <Header>
          <SectionHeading>Anomalies</SectionHeading>
          <NavRow>
            <NavTabLink to={Routes.ANOMALIES.LIST} end>Dashboard</NavTabLink>
            <NavTabLink to={Routes.ANOMALIES.DETECTORS.LIST}>Anomaly Detectors</NavTabLink>
          </NavRow>
        </Header>
        <div>{children}</div>
      </Main>
    </PageContentLayout>

  );
}
