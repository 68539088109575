type ChecksumTypeValue = 'NONE' | 'CRC32' | 'MD5' | 'SHA1' | 'SHA256';

const ChecksumTypes = {
  checksumTypes: [
    { value: 'NONE', label: 'No checksum' },
    { value: 'CRC32', label: 'CRC32 (little endian)' },
    { value: 'MD5', label: 'MD5' },
    { value: 'SHA1', label: 'SHA1' },
    { value: 'SHA256', label: 'SHA256' },
  ] as const,

  getChecksumType(checksumType: ChecksumTypeValue) {
    return this.checksumTypes.filter((t) => t.value === checksumType)[0];
  },
};

export default ChecksumTypes;
