import * as React from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';

import { Icon } from 'components/common';

const Backdrop = styled.div<{ $isConfirmDialog: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ $isConfirmDialog }) => ($isConfirmDialog ? 1051 : 1001)};

  background-color: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div<{ $maxWidth?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 75vw;
  max-width: ${({ $maxWidth }) => ($maxWidth || '600px')};
  max-height: 80vh;
  top: 10vh;

  background-color: ${({ theme }) => theme.colors.global.contentBackground};
  border: 1px solid ${({ theme }) => theme.colors.input.border};
`;

const Header = styled.div`
  posiiotn: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 16px;
  height: 55px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.input.border};
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  padding: 8px;
  color: ${({ theme }) => theme.colors.input.placeholder};

  &:hover {
    color: ${({ theme }) => theme.colors.global.textDefault};
  }
`;

const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  gap: 16px;
`;

const Footer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
  gap: 8px;
  height: 55px;
  border-top: 1px solid ${({ theme }) => theme.colors.input.border};
`;

type Props = {
  show: boolean,
  title: string | React.ReactNode,
  buttons?: React.ReactNode,
  children: React.ReactNode,
  maxWidth?: string,
  closeOnBackdrop?: boolean,
  confirmDialog?: boolean,
  onClose: () => void,
}

function Modal({ show, title, buttons, maxWidth, closeOnBackdrop, confirmDialog, children, onClose }: Props) {
  const handleBackdrop = React.useCallback((e: React.BaseSyntheticEvent) => {
    if (e.target === e.currentTarget && closeOnBackdrop) onClose();
  }, [onClose, closeOnBackdrop]);

  const closeOnEsc = React.useCallback((e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === 'Escape') onClose();
  }, [onClose]);

  React.useEffect(() => {
    document.addEventListener('keydown', closeOnEsc, false);

    return () => {
      document.removeEventListener('keydown', closeOnEsc, false);
    };
  }, [closeOnEsc]);

  const Component = React.useMemo(() => (
    show ? (
      <Backdrop onClick={handleBackdrop} $isConfirmDialog={confirmDialog} aria-label="backdrop">
        <Container $maxWidth={maxWidth} role="dialog" aria-label="modal" aria-modal="true">
          <Header>
            <h3>{title}</h3>
            <CloseIcon name="close" onClick={() => onClose()} />
          </Header>
          <Body aria-label="modal body">{children}</Body>
          {buttons && <Footer aria-label="modal footer">{buttons}</Footer>}
        </Container>
      </Backdrop>
    ) : null
  ), [show, title, buttons, maxWidth, children, confirmDialog, handleBackdrop, onClose]);

  return <>{ReactDom.createPortal(Component, document.body)}</>;
}

Modal.defaultProps = {
  buttons: undefined,
  closeOnBackdrop: undefined,
  confirmDialog: undefined,
  maxWidth: undefined,
};

export default Modal;
