import * as React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/common';
import { GLCheckbox, DropdownMenu } from 'security-app/components/common';
import { MenuItem } from 'components/bootstrap';
import DateFilter from 'views/components/widgets/overview-configuration/filters/DateFilter';

import type { FilterData, ColumnFilterData } from './ColumnFilter.types';

const FilterTrigger = styled.span<{ $open?: boolean }>(({ $open, theme }) => css`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: ${theme.colors.global.textDefault};

  & > svg {
    transition: rotate 0.2s;
    rotate: ${$open ? 180 : 0}deg;
  }
`);

const FilterItem = styled.div(({ theme }) => css`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  font-weight: normal;
  color: ${theme.colors.global.textDefault};
`);

type Props = {
  filterBy: string,
  type: 'text' | 'date',
  filterData: FilterData[],
  appliedFilters: ColumnFilterData,
  onClose: (filterKey: string, filterValue: FilterData[]) => void,
  children: React.ReactNode,
};

const ColumnFilter = ({ filterBy, filterData, appliedFilters, type, onClose, children }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [filterValues, setFilterValues] = React.useState<FilterData[]>(appliedFilters[filterBy] || []);

  React.useEffect(() => {
    setFilterValues(appliedFilters[filterBy] || []);
  }, [filterBy, appliedFilters]);

  const closeMenu = () => {
    onClose(filterBy, (filterValues.length > 0 ? filterValues : null));
  };

  const filterIncluded = (fData: FilterData) => !!filterValues.find(({ value }: { value: string }) => value === fData.value);

  const onFilterChange = (fData: FilterData) => () => {
    if (filterIncluded(fData)) {
      setFilterValues(filterValues.filter(({ value }: { value: string }) => value !== fData.value));
    } else {
      setFilterValues([...filterValues, fData]);
    }
  };

  const onFilterDateChange = (values: string[]) => {
    setFilterValues(values.map((value) => ({ label: value, value, type: 'string', backend: filterData[0].backend })));
  };

  const filterByType = () => {
    switch (type) {
      case 'date':
        return <DateFilter values={filterValues.map(({ value }) => value)} onChange={onFilterDateChange} />;
      default:
        return filterData.filter(({ value }: { value: string}) => !!value).map((fData: FilterData) => (
          <MenuItem key={`filter-${filterBy}-${fData.value}`} onClick={onFilterChange(fData)}>
            <FilterItem>
              <GLCheckbox onChange={onFilterChange(fData)}
                          checked={filterIncluded(fData)}
                          aria-label={`filter-${fData.label}`} />
              <span tabIndex={0}
                    role="checkbox"
                    aria-checked={filterIncluded(fData)}>
                {fData.label}
              </span>
            </FilterItem>
          </MenuItem>
        ));
    }
  };

  const showFilters = React.useMemo(() => (type === 'text' && filterData && filterData.length > 1)
      || (type === 'date' && filterData && filterData.length > 0), [filterData, type]);

  return showFilters ? (
    <DropdownMenu onClose={closeMenu}
                  closeOnItemClick={false}
                  trigger={(
                    <FilterTrigger $open={open} onClick={() => setOpen(!open)}>
                      <Icon name="expand_more" size="xs" />
                      {children}
                    </FilterTrigger>
    )}>
      {filterByType()}
    </DropdownMenu>
  ) : <span>{children}</span>;
};

export default ColumnFilter;
