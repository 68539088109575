import type { SymantecEDRGenericInputCreateRequest, SymantecEDRInputCreateRequest } from './types';

import type { FormDataType } from '../common/utils/types';

export const toSymantecEDRInputCreateRequest = ({
  managementIP,
  symantecEDRName,
  clientId,
  clientSecret,
  content_type,
  symantecEDRThrottleEnabled,
  pollingInterval,
  disableTLSCheck,
}: FormDataType): SymantecEDRInputCreateRequest => ({
  name: symantecEDRName.value,
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  management_ip: managementIP.value,
  enable_throttling: !!symantecEDRThrottleEnabled?.value,
  client_id: clientId.value,
  client_secret: clientSecret.value,
  content_type: content_type.value.split(','),
  disable_tls_check: disableTLSCheck.value,
});

export const toGenericInputCreateRequest = ({
  managementIP,
  clientId,
  clientSecret,
  symantecEDRName,
  symantecEDRThrottleEnabled,
  pollingInterval,
  content_type,
  disableTLSCheck,
}: FormDataType): SymantecEDRGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.symantecedr.symantecEDRInput',
  title: symantecEDRName.value,
  global: false,
  configuration: {
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    management_ip: managementIP.value,
    throttling_allowed: !!symantecEDRThrottleEnabled?.value,
    client_id: clientId.value,
    client_secret: clientSecret.value,
    content_type: content_type.value.split(','),
    disable_tls_check: disableTLSCheck.value,
  },
});
