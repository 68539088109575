type CompressionTypeValue = 'NONE' | 'GZIP' | 'SNAPPY' | 'LZ4' | 'ZSTD'

const CompressionTypes = {
  compressionTypes: [
    { value: 'NONE', label: 'No compression' },
    { value: 'GZIP', label: 'gzip' },
    { value: 'SNAPPY', label: 'Snappy' },
    { value: 'LZ4', label: 'LZ4' },
    { value: 'ZSTD', label: 'Zstandard - Recommended. Fast, with high compression.' },
  ] as const,

  getCompressionType(compressionType: CompressionTypeValue) {
    return this.compressionTypes.filter((t) => t.value === compressionType)[0];
  },
};

export default CompressionTypes;
