import type { PluginExports } from 'graylog-web-plugin/plugin';

import AppConfig from 'util/AppConfig';
import fetchForwarderNode from 'forwarder/fetchForwarderNode';
import fetchForwarderInput from 'forwarder/fetchForwarderInput';
import { SYSTEM_DROPDOWN_TITLE } from 'components/navigation/bindings';

import ForwarderPage from './ForwarderPage';
import ForwardersPage from './ForwardersPage';
import InputProfilesPage from './InputProfilesPage';
import InputProfilePage from './InputProfilePage';
import NewInputProfilePage from './NewInputProfilePage';
import ForwarderWizardPage from './wizard/ForwarderWizardPage';
import ForwarderInputDropdown from './message-loaders/ForwarderInputDropdown';
import ForwarderReceivedBy from './message-details/ForwarderReceivedBy';
import isLocalNode from './message-details/isLocalNode';

const forwarderBindings: PluginExports = {
  forwarder: [{
    ForwarderReceivedBy,
    isLocalNode,
    messageLoaders: {
      ForwarderInputDropdown,
    },
    fetchForwarderInput,
    fetchForwarderNode,
  }],
  routes: [
    { path: '/system/forwarders', component: ForwardersPage },
    { path: '/system/forwarders/new', component: ForwarderWizardPage },
    { path: '/system/forwarders/configure/:forwarderId', component: ForwarderWizardPage },
    { path: '/system/forwarders/:forwarderId', component: ForwarderPage },
    { path: '/system/input_profiles', component: InputProfilesPage },
    { path: '/system/input_profiles/new', component: NewInputProfilePage },
    { path: '/system/input_profiles/:inputProfileId', component: InputProfilePage },
  ],
  navigation: AppConfig.isCloud() ? [{
    description: SYSTEM_DROPDOWN_TITLE,
    children: [{ path: '/system/forwarders', description: 'Forwarders', permissions: 'forwarders:read' }],
  }] : [],
};

export default forwarderBindings;
