import * as React from 'react';

import useView from 'views/hooks/useView';

import ViewContext from './ViewContext';

const ViewProvider = ({ children }: React.PropsWithChildren) => {
  const view = useView();

  return (
    <ViewContext.Provider value={view}>
      {children}
    </ViewContext.Provider>
  );
};

export default ViewProvider;
