import React, { useCallback } from 'react';

import InstantArchivingActions from 'instant-archiving/InstantArchivingOverview/InstantArchivingActions';
import BulkActions from 'instant-archiving/InstantArchivingOverview/BulkActions';

const useTableElements = () => {
  const entityActions = useCallback((archive) => (
    <InstantArchivingActions archive={archive} />
  ), []);

  return {
    entityActions,
    bulkActions: <BulkActions />,
  };
};

export default useTableElements;
