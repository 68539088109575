import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'components/common';

type Props = {
  enabled: boolean,
};

const EnabledCell = ({ enabled }: Props) => (
  <Icon name={enabled ? 'check_circle' : 'cancel'} />
);

export default EnabledCell;

EnabledCell.propTypes = {
  enabled: PropTypes.bool,
};

EnabledCell.defaultProps = {
  enabled: false,
};
