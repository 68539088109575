import type { SymantecSESGenericInputCreateRequest, SymantecSESInputCreateRequest } from './types';

import type { FormDataType } from '../common/utils/types';

export const toSymantecSESInputCreateRequest = ({
  symantecSESName,
  streamGUID,
  contentTypes,
  sesBaseURL,
  symantecSESThrottleEnabled,
  pollingInterval,
  noOfChannels,
  oauthCredentials,
  checkpointIntervalSeconds,
  streamConnectionTimeout,
}: FormDataType): SymantecSESInputCreateRequest => ({
  name: symantecSESName.value,
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  enable_throttling: !!symantecSESThrottleEnabled?.value,
  content_types: contentTypes.value.split(','),
  no_of_channels: noOfChannels.value,
  ses_base_url: sesBaseURL.value,
  stream_guid: streamGUID.value,
  checkpoint_interval_seconds: checkpointIntervalSeconds.value,
  oauth_credentials: oauthCredentials.value,
  stream_connection_timeout: streamConnectionTimeout.value,
});

export const toGenericInputCreateRequest = ({
  streamGUID,
  symantecSESName,
  symantecSESThrottleEnabled,
  pollingInterval,
  contentTypes,
  noOfChannels,
  OAuthCredentials,
  streamConnectionTimeout,
  checkpointIntervalSeconds,
}: FormDataType): SymantecSESGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.symantecses.symantecSESInput',
  title: symantecSESName.value,
  global: false,
  configuration: {
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    throttling_allowed: !!symantecSESThrottleEnabled?.value,
    content_types: contentTypes.value.split(','),
    no_of_channels: noOfChannels.value,
    stream_guid: streamGUID.value,
    checkpoint_interval_seconds: checkpointIntervalSeconds.value,
    oauth_credentials: OAuthCredentials.value,
    stream_connection_timeout: streamConnectionTimeout.value,
  },
});
