import * as React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';

import { LinkContainer } from 'components/common/router';
import { Button, Col, DropdownButton, MenuItem } from 'components/bootstrap';
import { EntityListItem } from 'components/common';
import Routes from 'routing/Routes';
import type { LicenseStatus } from 'archive/types';
import type { Report as ReportType } from 'report/types';
import ReportsActions from 'report/ReportsActions';
import ButtonToolbar from 'components/bootstrap/ButtonToolbar';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';

import ReportsStore from '../ReportsStore';
import { isValidLicense } from '../logic/license';

const _reportHasRecipients = (delivery: ReportType['delivery']) => delivery && (
  (Array.isArray(delivery.user_receivers) && delivery.user_receivers.length > 0)
    || (Array.isArray(delivery.email_receivers) && delivery.email_receivers.length > 0)
);

const sendReportText = (licenseStatus: LicenseStatus, reportHasRecipients: boolean) => {
  if (!isValidLicense(licenseStatus)) {
    return 'Sending report disabled, no valid license found';
  }

  if (!reportHasRecipients) {
    return 'Sending report disabled, add recipients first';
  }

  return 'Send report now';
};

const Delivery = ({ delivery }: { delivery: ReportType['delivery'] }) => {
  if (!delivery || isEmpty(delivery) || !delivery.active) {
    return <p>This report is not scheduled to be sent automatically.</p>;
  }

  const frequencies = delivery.scheduling?.map(({ frequency }) => frequency.toLocaleLowerCase());
  const uniqFrequencies = uniq(frequencies);

  const sortedFrequencies = sortBy(uniqFrequencies, (item) => frequencies.indexOf(item));

  return <p>This report is scheduled to be sent automatically ({sortedFrequencies.join(', ')}).</p>;
};

const ReportActions = ({ report, licenseStatus }: { report: ReportType, licenseStatus: LicenseStatus }) => {
  const reportHasRecipients = _reportHasRecipients(report.delivery);
  const isSendReportEnabled = reportHasRecipients && isValidLicense(licenseStatus);
  const sendTelemetry = useSendTelemetry();

  const onDeleteReport = async () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.REPORT.DELETED, {
      app_pathname: 'report',
      app_section: 'report-form',
    });

    // eslint-disable-next-line no-alert
    if (window.confirm(`Do you really want to delete report "${report.title}"? This action cannot be undone.`)) {
      await ReportsActions.delete(report.id, report.title);
    }
  };

  const onSendReport = async () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.REPORT.SENT, {
      app_pathname: 'report',
      app_section: 'report-form',
    });

    await ReportsActions.sendEmail(report.id, report.title);
  };

  // TODO: Check permissions here
  return (
    <ButtonToolbar>
      <LinkContainer key="contents-button" to={Routes.pluginRoute('REPORTS_REPORTID_CONTENTS')(report.id)}>
        <Button>Contents</Button>
      </LinkContainer>
      <LinkContainer key="configuration-button" to={Routes.pluginRoute('REPORTS_REPORTID_CONFIGURATION')(report.id)}>
        <Button>Configuration</Button>
      </LinkContainer>
      <DropdownButton key="more-actions-button"
                      title="More actions"
                      pullRight
                      id={`more-actions-dropdown-${report.id}`}>
        <MenuItem href={Routes.pluginRoute('REPORTS_REPORTID_HISTORY')(report.id)}>Report history</MenuItem>
        <MenuItem href={ReportsStore.getDownloadURL(report.id)} target="_blank">Download report now</MenuItem>
        <MenuItem disabled={!isSendReportEnabled} onSelect={onSendReport}>
          {sendReportText(licenseStatus, reportHasRecipients)}
        </MenuItem>
        <MenuItem divider />
        <MenuItem onSelect={onDeleteReport}>Delete</MenuItem>
      </DropdownButton>
    </ButtonToolbar>
  );
};

const Report = ({ report, licenseStatus }: { report: ReportType, licenseStatus: LicenseStatus }) => (
  <EntityListItem key={`entry-list-${report.id}`}
                  title={report.title}
                  description={report.description}
                  actions={<ReportActions report={report} licenseStatus={licenseStatus} />}
                  contentRow={<Col md={12}><Delivery delivery={report.delivery} /></Col>} />
);

Report.propTypes = {
  report: PropTypes.object.isRequired,
  licenseStatus: PropTypes.object.isRequired,
};

export default Report;
