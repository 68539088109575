import * as React from 'react';
import styled from 'styled-components';

import { Menu } from 'components/bootstrap';

const Trigger = styled.div`
  display: inline-block;
  cursor: pointer;
`;

type Props = {
  trigger: React.ReactNode,
  position?: 'bottom-start' | 'bottom-end';
  children: React.ReactNode,
  onClose?: () => void,
  closeOnItemClick?: boolean,
};

const DropdownMenu = ({ trigger, position, children, onClose, closeOnItemClick }: Props) => (
  <Menu position={position} onClose={onClose} closeOnItemClick={closeOnItemClick}>
    <Menu.Target>
      <Trigger>{trigger}</Trigger>
    </Menu.Target>
    <Menu.Dropdown>
      {children}
    </Menu.Dropdown>
  </Menu>
);

DropdownMenu.defaultProps = {
  position: 'bottom-start',
  onClose: () => {},
  closeOnItemClick: true,
};

export default DropdownMenu;
