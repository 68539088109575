import useLicenseCheck from 'license/useLicenseCheck';
import useCurrentUser from 'hooks/useCurrentUser';
import { isPermitted } from 'util/PermissionsMixin';

const useActionCondition = () => {
  const { security } = useLicenseCheck();
  const currentUser = useCurrentUser();

  return security?.isValid && isPermitted(currentUser.permissions, 'investigations:edit');
};

export default useActionCondition;
