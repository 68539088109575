export default {
  convert({ id, investigations, total_results, type }) {
    return {
      id,
      investigations,
      totalResults: total_results,
      type,
    };
  },
};
