import * as React from 'react';
import type { CustomColors } from '@graylog/sawmill';

import { COLOR_SCHEME_DARK, COLOR_SCHEME_LIGHT } from 'theme/constants';

import ThemeEditorColor from './ThemeEditorColor';

interface OnChangeParams {
  mode: string,
  key: string,
  type: string,
  hex: string,
}

type Props = {
  colorName: string,
  colorType: string,
  colorKey: string,
  currentThemeColors: CustomColors | undefined,
  onChange: ({ mode, key, type, hex }: OnChangeParams) => void,
}

const ThemeEditorRow = ({ colorType, colorKey, colorName, currentThemeColors, onChange }: Props) => {
  const light = currentThemeColors?.[COLOR_SCHEME_LIGHT][colorType][colorKey];
  const dark = currentThemeColors?.[COLOR_SCHEME_DARK][colorType][colorKey];

  const handleChangeLight = (hex) => {
    onChange({ mode: COLOR_SCHEME_LIGHT, key: colorKey, type: colorType, hex });
  };

  const handleChangeDark = (hex) => {
    onChange({ mode: COLOR_SCHEME_DARK, key: colorKey, type: colorType, hex });
  };

  return (
    <li>
      <span>{colorName}</span>

      <ThemeEditorColor color={light} onChange={handleChangeLight} label={`edit light ${colorName} color`} />
      <ThemeEditorColor color={dark} onChange={handleChangeDark} label={`edit dark ${colorName} color`} />

      <span />
    </li>
  );
};

export default ThemeEditorRow;
