import React from 'react';

import useLicenseStatusForSubject from 'license/hooks/useLicenseStatusForSubject';
import { Link } from 'components/common/router';
import { Alert } from 'components/bootstrap';
import { IfPermitted } from 'components/common';
import Routes from 'routing/Routes';
import AppConfig from 'util/AppConfig';

const isCloud = AppConfig.isCloud();

const WarmTierLicenseStatus = () => {
  const { isLoading, data, error } = useLicenseStatusForSubject('/license/enterprise');

  if (isLoading || (data && !data.status?.expired) || (error && !(error?.status === 404))) return null;

  return (
    <Alert bsStyle="danger" title="Warm Tier is disabled">
      <p>
        Warm Tier is disabled because there is no valid license.<br />
        Note that any data previously stored within the Warm Tier will remain searchable.
      </p>
      {isCloud
        ? (<>Contact your Graylog account manager.</>)
        : (
          <IfPermitted permissions="licenses:create">
            <p>
              See <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for details.
            </p>
          </IfPermitted>
        )}
    </Alert>
  );
};

export default WarmTierLicenseStatus;
