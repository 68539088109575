import * as React from 'react';
import styled from 'styled-components';

import type { SecurityEventAPIType } from 'security-app/hooks/api/securityEventsAPI.types';
import type { EventDefinition } from 'components/event-definitions/event-definitions-types';

import ActionsRow from './ActionsRow';
import EventDetails from './EventDetails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
`;

type Props = {
  securityEvent: SecurityEventAPIType,
  eventDefinitions: { [key: string]: EventDefinition },
  onReplaySearch?: () => void,
};

function Index({ securityEvent, eventDefinitions, onReplaySearch }: Props) {
  return (
    <Container>
      <ActionsRow securityEvent={securityEvent} onReplaySearch={onReplaySearch} />
      <EventDetails securityEvent={securityEvent} eventDefinitions={eventDefinitions} />
    </Container>
  );
}

Index.defaultProps = {
  onReplaySearch: undefined,
};

export default Index;
