import AppConfig from 'util/AppConfig';

import Routes from './o365/common/Routes';
import O365InputConfiguration from './o365/O365InputConfiguration';
import O365App from './o365/O365App';
import EmbeddedO365App from './o365/EmbeddedO365App';
import GmailApp from './gmail/GmailApp';
import gmailRoutes from './gmail/common/Routes';
import GmailInputConfiguration from './gmail/GmailInputConfiguration';
import EmbeddedGmailApp from './gmail/EmbeddedGmailApp';
import WorkspaceApp from './googleworkspace/WorkspaceApp';
import workspaceRoutes from './googleworkspace/common/Routes';
import WorkspaceInputConfiguration from './googleworkspace/WorkspaceInputConfiguration';
import EmbeddedWorkspaceApp from './googleworkspace/EmbeddedWorkspaceApp';
import GCPApp from './gcp/GCPApp';
import Route from './gcp/common/Routes';
import GCPInputConfiguration from './gcp/GCPInputConfiguration';
import EmbeddedGCPApp from './gcp/EmbeddedGCPApp';
import DefenderApp from './microsoftdefender/DefenderApp';
import DefenderRoutes from './microsoftdefender/common/Routes';
import DefenderInputConfiguration from './microsoftdefender/DefenderInputConfiguration';
import EmbeddedDefenderApp from './microsoftdefender/EmbeddedDefenderApp';
import AzureApp from './azure/AzureApp';
import AzureRoutes from './azure/common/Routes';
import AzureLogsInputConfiguration from './azure/AzureLogsInputConfiguration';
import EmbeddedAzureApp from './azure/EmbeddedAzureApp';
import AWSSecurityLakeApp from './awssecuritylake/AWSSecurityLakeApp';
import AWSSecurityLakeRoutes from './awssecuritylake/common/Routes';
import AWSSecurityLakeInputConfiguration from './awssecuritylake/AWSSecurityLakeInputConfiguration';
import EmbeddedAWSSecurityLakeApp from './awssecuritylake/EmbeddedAWSSecurityLakeApp';
import CrowdStrikeApp from './crowdstrike/CrowdStrikeApp';
import CrowdStrikeRoutes from './crowdstrike/common/Routes';
import CrowdStrikeInputConfiguration from './crowdstrike/CrowdStrikeInputConfiguration';
import EmbeddedCrowdStrikeApp from './crowdstrike/EmbeddedCrowdStrikeApp';
import F5NetworksApp from './f5networks/F5NetworksApp';
import F5NetworksRoutes from './f5networks/common/Routes';
import F5NetworksInputConfiguration from './f5networks/F5NetworksInputConfiguration';
import EmbeddedF5NetworksApp from './f5networks/EmbeddedF5NetworksApp';
import SalesforceApp from './salesforce/SalesforceApp';
import SalesforceRoutes from './salesforce/common/Routes';
import SalesforceInputConfiguration from './salesforce/SalesforceInputConfiguration';
import EmbeddedSalesforceApp from './salesforce/EmbeddedSalesforceApp';
import SymantecEDRApp from './symantecedr/SymantecEDRApp';
import SymantecEDRRoutes from './symantecedr/Routes';
import SymantecEDRInputConfiguration from './symantecedr/SymantecEDRInputConfiguration';
import EmbeddedSymantecEDRApp from './symantecedr/EmbeddedSymantecEDRApp';
import SymantecSESApp from './symantecses/SymantecSESApp';
import SymantecSESRoutes from './symantecses/Routes';
import SymantecSESInputConfiguration from './symantecses/SymantecSESInputConfiguration';
import EmbeddedSymantecSESApp from './symantecses/EmbeddedSymantecSESApp';
import ScriptNotificationDetails from './script-notifications/ScriptNotificationDetails';
import ScriptNotificationForm from './script-notifications/ScriptNotificationForm';
import ScriptNotificationSummary from './script-notifications/ScriptNotificationSummary';
import LDAPAdapterFieldSet from './components/adapters/ldap/LDAPAdapterFieldSet';
import LDAPAdapterSummary from './components/adapters/ldap/LDAPAdapterSummary';
import LDAPAdapterDocumentation from './components/adapters/ldap/LDAPAdapterDocumentation';
import URLhausAdapterFieldSet from './components/adapters/urlhaus/URLhausAdapterFieldSet';
import URLhausAdapterSummary from './components/adapters/urlhaus/URLhausAdapterSummary';
import URLhausAdapterDocumentation from './components/adapters/urlhaus/URLhausAdapterDocumentation';
import ThreatFoxAdapterFieldSet from './components/adapters/threatfox/ThreatFoxAdapterFieldSet';
import ThreatFoxAdapterSummary from './components/adapters/threatfox/ThreatFoxAdapterSummary';
import ThreatFoxAdapterDocumentation from './components/adapters/threatfox/ThreatFoxAdapterDocumentation';
import { GreyNoiseMultiAdapterDocumentation, GreyNoiseMultiAdapterFieldSet, GreyNoiseMultiAdapterSummary } from './components/adapters/greynoise';

const enterpriseIntegrationsExport = {
  routes: [
    { path: Routes.INTEGRATIONS.O365.ACTIVITYAPI.index, component: O365App },
    { path: gmailRoutes.INTEGRATIONS.Gmail.ACTIVITYAPI.index, component: GmailApp },
    { path: workspaceRoutes.INTEGRATIONS.Workspace.ACTIVITYAPI.index, component: WorkspaceApp },
    { path: Route.INTEGRATIONS.GCP.ACTIVITYAPI.index, component: GCPApp },
    { path: AzureRoutes.INTEGRATIONS.Azure.ACTIVITYAPI.index, component: AzureApp },
    { path: AWSSecurityLakeRoutes.INTEGRATIONS.AWSSecurityLake.ACTIVITYAPI.index, component: AWSSecurityLakeApp },
    { path: DefenderRoutes.INTEGRATIONS.Defender.ACTIVITYAPI.index, component: DefenderApp },
    { path: CrowdStrikeRoutes.INTEGRATIONS.CrowdStrike.ACTIVITYAPI.index, component: CrowdStrikeApp },
    { path: F5NetworksRoutes.INTEGRATIONS.F5Networks.ACTIVITYAPI.index, component: F5NetworksApp },
    { path: SalesforceRoutes.INTEGRATIONS.Salesforce.ACTIVITYAPI.index, component: SalesforceApp },
    { path: SymantecEDRRoutes.INTEGRATIONS.SymantecEDR.ACTIVITYAPI.index, component: SymantecEDRApp },
    { path: SymantecSESRoutes.INTEGRATIONS.SymantecSES.ACTIVITYAPI.index, component: SymantecSESApp },
  ],
  inputConfiguration: [
    {
      type: 'org.graylog.enterprise.integrations.office365.Office365Input',
      component: O365InputConfiguration,
      embeddedComponent: EmbeddedO365App,
    },
    {
      type: 'org.graylog.enterprise.integrations.gmail.GmailInput',
      component: GmailInputConfiguration,
      embeddedComponent: EmbeddedGmailApp,
    },
    {
      type: 'org.graylog.enterprise.integrations.googleworkspace.WorkspaceInput',
      component: WorkspaceInputConfiguration,
      embeddedComponent: EmbeddedWorkspaceApp,
    },
    {
      type: 'org.graylog.enterprise.integrations.gcp.GCPInput',
      component: GCPInputConfiguration,
      embeddedComponent: EmbeddedGCPApp,
    },
    {
      type: 'org.graylog.enterprise.integrations.microsoftdefender.DefenderInput',
      component: DefenderInputConfiguration,
      embeddedComponent: EmbeddedDefenderApp,
    },
    {
      type: 'org.graylog.enterprise.integrations.azure.AzureLogsInput',
      component: AzureLogsInputConfiguration,
      embeddedComponent: EmbeddedAzureApp,
    },
    {
      type: 'org.graylog.enterprise.integrations.awssecuritylake.SecurityLakeInput',
      component: AWSSecurityLakeInputConfiguration,
      embeddedComponent: EmbeddedAWSSecurityLakeApp,
    },
    {
      type: 'org.graylog.enterprise.integrations.crowdstrike.CrowdStrikeInput',
      component: CrowdStrikeInputConfiguration,
      embeddedComponent: EmbeddedCrowdStrikeApp,
    },
    {
      type: 'org.graylog.enterprise.integrations.f5networks.F5Input',
      component: F5NetworksInputConfiguration,
      embeddedComponent: EmbeddedF5NetworksApp,
    },
    {
      type: 'org.graylog.enterprise.integrations.salesforce.SalesforceInput',
      component: SalesforceInputConfiguration,
      embeddedComponent: EmbeddedSalesforceApp,
    },
    {
      type: 'org.graylog.enterprise.integrations.symantecedr.SymantecEDRInput',
      component: SymantecEDRInputConfiguration,
      embeddedComponent: EmbeddedSymantecEDRApp,
    },
    {
      type: 'org.graylog.enterprise.integrations.symantecses.SymantecSESInput',
      component: SymantecSESInputConfiguration,
      embeddedComponent: EmbeddedSymantecSESApp,
    },
  ],
  lookupTableAdapters: [
    {
      type: 'LDAP',
      displayName: 'Active Directory User Lookup',
      formComponent: LDAPAdapterFieldSet,
      summaryComponent: LDAPAdapterSummary,
      documentationComponent: LDAPAdapterDocumentation,
    },
    {
      type: 'GreyNoise Lookup [Enterprise]',
      displayName: 'GreyNoise Full IP Lookup [Enterprise]',
      formComponent: GreyNoiseMultiAdapterFieldSet,
      summaryComponent: GreyNoiseMultiAdapterSummary,
      documentationComponent: GreyNoiseMultiAdapterDocumentation,
    },
    {
      type: 'urlhaus',
      displayName: 'URLhaus Malware Distribution URL Lookup [Enterprise]',
      formComponent: URLhausAdapterFieldSet,
      summaryComponent: URLhausAdapterSummary,
      documentationComponent: URLhausAdapterDocumentation,
    },
    {
      type: 'threatfox',
      displayName: 'ThreatFox IOC Lookup [Enterprise]',
      formComponent: ThreatFoxAdapterFieldSet,
      summaryComponent: ThreatFoxAdapterSummary,
      documentationComponent: ThreatFoxAdapterDocumentation,
    },
  ],
  eventNotificationTypes: AppConfig.isCloud() ? [] : [
    {
      type: 'script-notification-v1',
      displayName: 'Script Notification [Enterprise]',
      formComponent: ScriptNotificationForm,
      summaryComponent: ScriptNotificationSummary,
      detailsComponent: ScriptNotificationDetails,
      defaultConfig: ScriptNotificationForm.defaultConfig,
    },
  ],
};

export default enterpriseIntegrationsExport;
