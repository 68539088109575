import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';

import TitleFormGroup from 'search-filter/components/form-elements/TitleFormGroup';
import DescriptionFormGroup from 'search-filter/components/form-elements/DescriptionFormGroup';
import { Button, Input } from 'components/bootstrap';
import { HoverForHelp, Icon, ModalSubmit } from 'components/common';
import FormWarningsContext from 'contexts/FormWarningsContext';
import FormWarningsProvider from 'contexts/FormWarningsProvider';
import type { SearchFilter } from 'views/types';
import validateSearchFilterForm from 'search-filter/validateForm';
import QueryStringFormGroup from 'search-filter/components/form-elements/QueryStringFromGroup';
import useUserDateTime from 'hooks/useUserDateTime';
import usePluginEntities from 'hooks/usePluginEntities';
import {
  pluggableValidationPayload,
} from 'views/logic/searchbar/pluggableSearchBarControlsHandler';
import useSearchFormValues from 'search-filter/hooks/useSearchFormValues';
import ValidateOnParameterChange from 'views/components/searchbar/ValidateOnParameterChange';
import useHandlerContext from 'search-filter/hooks/useHandlerContext';
import useParameters from 'search-filter/hooks/useParameters';

export type SearchFilterEdit = Omit<SearchFilter, 'type'>;

type FormValues = Partial<SearchFilterEdit> & { checked?: boolean };

type Props = {
  checkboxLabel: string,
  helpText: string,
  helpTitle: string,
  formDescription?: React.ReactNode,
  initialValues?: Partial<SearchFilterEdit>,
  onCancel: () => void,
  onSubmit: (formValues: SearchFilterEdit, checked: boolean) => void,
  showCheckbox?: boolean,
  submitButtonText: string,
  submitLoadingText: string,
};

const StyledSaveFilter = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
`;

const StyledFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledHoverForHelp = styled((props: any) => <HoverForHelp {...props} />)`
  margin-left: 10px;
`;

const ToggleButton = styled(Button)`
  padding: 0;
  margin-bottom: 10px;
`;

const ToggleIcon = styled(Icon)`
  margin-left: 5px;
`;

const SearchFilterForm = ({
  checkboxLabel,
  helpText,
  helpTitle,
  formDescription,
  initialValues,
  onCancel,
  onSubmit,
  showCheckbox,
  submitButtonText,
  submitLoadingText,
}: Props) => {
  const { setFieldWarning } = useContext(FormWarningsContext);
  const { userTimezone } = useUserDateTime();
  const pluggableSearchBarControls = usePluginEntities('views.components.searchBar');
  const { parameterBindings, timerange, streams } = useSearchFormValues();
  const [showTitleAndDescription, setShowTitleAndDescription] = useState<boolean>(false);
  const _onSubmit = useCallback(({ checked, ...formValues }: FormValues) => onSubmit(formValues as SearchFilterEdit, checked), [onSubmit]);
  const handlerContext = useHandlerContext();
  const _validate = useCallback((values: FormValues) => validateSearchFilterForm(
    values, {
      setFieldWarning,
      pluggableValidationPayload: pluggableValidationPayload({ ...values, parameterBindings }, handlerContext, pluggableSearchBarControls),
      userTimezone,
    }), [setFieldWarning, parameterBindings, handlerContext, pluggableSearchBarControls, userTimezone]);
  const parametersContext = useParameters();
  const parameters = parametersContext?.parameters;

  return (
    <Formik onSubmit={_onSubmit}
            validateOnMount
            validate={_validate}
            initialValues={initialValues}>
      {({ isValid, values, isSubmitting }) => {
        const disableSubmit = !isValid || !values.queryString;

        return (
          <Form className="form">
            <QueryStringFormGroup timeRange={timerange}
                                  streams={streams}
                                  labelClassName=""
                                  wrapperClassName="" />
            <ToggleButton bsStyle="link"
                          onClick={() => {
                            setShowTitleAndDescription((cur) => !cur);
                          }}>
              Configure title and description<ToggleIcon name={showTitleAndDescription ? 'expand_less' : 'expand_more'} />
            </ToggleButton>
            {showTitleAndDescription && (
              <>
                <TitleFormGroup labelClassName=""
                                wrapperClassName="" />
                <DescriptionFormGroup labelClassName=""
                                      wrapperClassName="" />
              </>
            )}
            {formDescription && formDescription}
            <StyledFlexDiv>
              <div>
                {showCheckbox && (
                  <StyledSaveFilter>
                    <Field name="checked">
                      {({ field: { name, value, onChange }, meta }) => (
                        <Input id={name}
                               error={meta?.error}
                               label={checkboxLabel}
                               type="checkbox"
                               onChange={onChange}
                               formGroupClassName=""
                               value={value ? 'checked' : ''} />
                      )}
                    </Field>
                    <StyledHoverForHelp title={helpTitle}>
                      {helpText}
                    </StyledHoverForHelp>
                  </StyledSaveFilter>
                )}
              </div>
              <ModalSubmit onCancel={onCancel}
                           bsSize="small"
                           disabledSubmit={disableSubmit}
                           submitButtonText={submitButtonText}
                           submitLoadingText={submitLoadingText}
                           isAsyncSubmit
                           isSubmitting={isSubmitting} />
            </StyledFlexDiv>
            <ValidateOnParameterChange parameters={parameters} />
          </Form>
        );
      }}
    </Formik>
  );
};

const defaultProps = {
  initialValues: { queryString: '', title: '', description: '', checked: '' },
  showCheckbox: true,
  formDescription: undefined,
};

SearchFilterForm.defaultProps = defaultProps;

const SearchFilterFormWrapper = (props: Props) => <FormWarningsProvider><SearchFilterForm {...props} /></FormWarningsProvider>;

SearchFilterFormWrapper.defaultProps = defaultProps;

SearchFilterFormWrapper.propTypes = {
  checkboxLabel: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired,
  helpTitle: PropTypes.string.isRequired,
  formDescription: PropTypes.node,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showCheckbox: PropTypes.bool,
  submitLoadingText: PropTypes.string.isRequired,
};

export default SearchFilterFormWrapper;
