import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useModalContext } from 'security-app/components/common/Modal/ModalContext';
import DefinitionFormModal from 'security-app/components/SecurityEvents/Definitions/DefinitionFormModal';

function EventDefinitionHOC() {
  const queryClient = useQueryClient();
  const { entity: eventDefinition, setModal, setEntity } = useModalContext();

  const onClose = React.useCallback(() => {
    setModal(null);
    setEntity(null);
  }, [setEntity, setModal]);

  const handleSubmit = React.useCallback(() => {
    onClose();
    queryClient.invalidateQueries(['get-event-definition-details', eventDefinition.id]);
  }, [eventDefinition.id, onClose, queryClient]);

  return (
    <DefinitionFormModal eventDefinition={eventDefinition}
                         onSubmit={handleSubmit}
                         onCancel={onClose}
                         loading={!eventDefinition}
                         onClose={onClose} />
  );
}

export default EventDefinitionHOC;
