import * as React from 'react';

import { Link } from 'components/common/router';
import Routes from 'routing/Routes';
import { extractDurationAndUnit } from 'components/common/TimeUnitInput';
import { Spinner } from 'components/common';
import type { SearchFilter } from 'components/event-definitions/event-definitions-types';
import { useGetStreams } from 'security-app/hooks/useSigmaAPI';

import type { SigmaConfigType } from './eventSigma.types';

const TIME_UNITS = ['HOURS', 'MINUTES', 'SECONDS'];

type Props = {
  config: SigmaConfigType
};

function SigmaEventSummary({ config }: Props) {
  const { streams, loadingStreams } = useGetStreams();
  const streamNamesMap = React.useMemo(() => (streams || []).reduce((acc, stream) => (
    { ...acc, [stream.id]: stream.title }
  ), {}), [streams]);

  const searchWithin = extractDurationAndUnit(config.search_within_ms, TIME_UNITS);
  const executeEvery = extractDurationAndUnit(config.execute_every_ms, TIME_UNITS);

  if (loadingStreams) {
    return (
      <div>
        <Spinner text="Loading Sigma Event summary ..." />
      </div>
    );
  }

  return (
    <dl>
      <dt>Search within</dt>
      <dd>{searchWithin.duration} {searchWithin.unit.toLowerCase()}</dd>
      <dt>Execute every</dt>
      <dd>{executeEvery.duration} {executeEvery.unit.toLowerCase()}</dd>
      <dt>Search Filters</dt>
      {config.filters.length === 0 ? <dd><i>No filters configured</i></dd> : (
        <dd>
          {config.filters.map((filter: SearchFilter) => (
            <div key={filter.id}>
              {filter.title ? `${filter.title} -> ` : null}<code>{filter.queryString}</code>
            </div>
          ))}
        </dd>
      )}
      <dt>Streams</dt>
      {config.streams.length === 0 ? <dd><i>No streams configured</i></dd> : (
        <dd>
          {config.streams.map((streamId) => (
            <Link key={streamId} to={Routes.stream_search(streamId)}>
              {streamNamesMap[streamId]}
            </Link>
          ))}
        </dd>
      )}
    </dl>
  );
}

export default SigmaEventSummary;
