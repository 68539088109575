import isEqual from 'lodash/isEqual';
import type { MutableRefObject } from 'react';

import type CancellablePromise from 'logic/rest/CancellablePromise';
import UserNotification from 'util/UserNotification';
import type { SearchTypeOptions } from 'views/logic/search/GlobalOverride';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import type { SearchExecutionResult } from 'views/types';

import { orderMessages } from './ListStateProvider';
import type { LogViewState } from './LogViewStateProvider';

import type { LogViewSearchTypeData, PageRefs } from '../LogViewWidget';

const MAX_VISIBLE_PAGES = 2;

const _onAppendLoadedMessages = ({
  internalListState,
  loadedAllPrevMessages,
  pageRefs,
  updateListState,
}: {
  internalListState: LogViewState['listState'],
  loadedAllPrevMessages: boolean,
  pageRefs: PageRefs,
  updateListState: (
    listState: LogViewState['listState'],
    scrollPositionUpdate: LogViewState['scrollPositionUpdate']
  ) => void,
}) => (newAfter, newMessages) => {
  const newMessagesOrdered = orderMessages(newMessages);

  if (internalListState.after && !isEqual(internalListState.after, newAfter)) {
    const topVisiblePage = internalListState.visiblePagesIds.max();
    const bottomVisiblePage = internalListState.visiblePagesIds.min();
    const newPageIndex = topVisiblePage + 1;
    let newVisiblePagesIds = internalListState.visiblePagesIds.add(newPageIndex);

    if (newPageIndex - bottomVisiblePage >= MAX_VISIBLE_PAGES) {
      newVisiblePagesIds = newVisiblePagesIds.delete(bottomVisiblePage);
    }

    // It is necessary to define targetPageOffsetTop before we update the list state
    const scrollPositionUpdate = {
      direction: 'up' as const,
      targetPage: newPageIndex - 1,
      targetPageOffsetTop: pageRefs.current[newPageIndex - 1].offsetTop,
      loadedAllPrevMessages,
    };

    updateListState(
      {
        ...internalListState,
        after: newAfter,
        loadedPages: { ...internalListState.loadedPages, [newPageIndex]: newMessagesOrdered },
        loadedMessagesCount: internalListState.loadedMessagesCount + newMessagesOrdered.length,
        visiblePagesIds: newVisiblePagesIds,
      },
      scrollPositionUpdate,
    );
  }
};

interface LogsResult {
  type: 'logs',
  after: any,
  messages: Array<any>,
  total: number,
}

declare module 'views/types' {
  interface SearchTypeResultTypes {
    logs: LogsResult,
  }
}

type RefreshSearch = (searchTypeOptions: SearchTypeOptions, effectiveTimeRange: AbsoluteTimeRange) => Promise<SearchExecutionResult>;

const _requestPrevPage = ({
  refreshSearch,
  appendLoadedMessages,
  effectiveTimerange,
  internalListState,
  loadPrevPromiseRef,
  queryId,
  searchTypeId,
}: {
  refreshSearch: RefreshSearch,
  appendLoadedMessages: (newAfter: LogViewSearchTypeData['after'], newMessages: LogViewSearchTypeData['messages']) => void,
  effectiveTimerange: LogViewSearchTypeData['effectiveTimerange'],
  internalListState: LogViewState['listState'],
  loadPrevPromiseRef: MutableRefObject<Promise<void>>,
  queryId: string,
  searchTypeId: string,
}) => {
  if (!loadPrevPromiseRef.current) {
    if (internalListState.after) {
      const searchTypePayload: SearchTypeOptions<{ after: any }> = { [searchTypeId]: { after: internalListState.after } };

      // eslint-disable-next-line no-param-reassign
      loadPrevPromiseRef.current = refreshSearch(searchTypePayload, effectiveTimerange).then((result) => {
        const searchTypeResult = result.result.results[queryId].searchTypes[searchTypeId] as LogsResult;
        appendLoadedMessages(searchTypeResult.after, searchTypeResult.messages);
        // eslint-disable-next-line no-param-reassign
        loadPrevPromiseRef.current = undefined;
      }).catch((error) => {
        // eslint-disable-next-line no-param-reassign
        loadPrevPromiseRef.current = undefined;
        UserNotification.error(`Fetching previous log messages failed with status: ${error}`);
      });
    } else {
      throw Error(`Can not reexecute search type with id ${searchTypeId}, because parameter "after" is missing.`);
    }
  }
};

const _prevPageIsInState = (listState: LogViewState['listState']) => {
  const topVisiblePage = listState.visiblePagesIds.max();
  const newPageIndex = topVisiblePage + 1;

  return !!listState.loadedPages[newPageIndex];
};

const _loadPrevPageFromState = ({
  internalListState,
  loadedAllPrevMessages,
  pageRefs,
  updateListState,
}: {
  internalListState: LogViewState['listState'],
  loadedAllPrevMessages: boolean,
  pageRefs: PageRefs,
  updateListState: (
    listState: LogViewState['listState'],
    scrollPositionUpdate: LogViewState['scrollPositionUpdate']
  ) => void,
}) => {
  const topVisiblePage = internalListState.visiblePagesIds.max();
  const bottomVisiblePage = internalListState.visiblePagesIds.min();
  const newTopPageIndex = topVisiblePage + 1;
  let newVisiblePagesIds = internalListState.visiblePagesIds.add(newTopPageIndex);

  if (newTopPageIndex - bottomVisiblePage >= MAX_VISIBLE_PAGES) {
    newVisiblePagesIds = newVisiblePagesIds.delete(bottomVisiblePage);
  }

  // It is necessary to define targetPageOffsetTop before we update the list state
  const scrollPositionUpdate = {
    direction: 'up' as const,
    targetPage: newTopPageIndex - 1,
    targetPageOffsetTop: pageRefs.current[newTopPageIndex - 1].offsetTop,
    loadedAllPrevMessages,
  };

  updateListState(
    {
      ...internalListState,
      visiblePagesIds: newVisiblePagesIds,
    },
    scrollPositionUpdate,
  );
};

const _loadNextPageFromState = ({
  internalListState,
  loadedAllPrevMessages,
  pageRefs,
  updateListState,
}: {
  internalListState: LogViewState['listState'],
  loadedAllPrevMessages: boolean,
  pageRefs: PageRefs,
  updateListState: (
    listState: LogViewState['listState'],
    scrollPositionUpdate: LogViewState['scrollPositionUpdate']
  ) => void,
}) => {
  const topVisiblePage = internalListState.visiblePagesIds.max();
  const bottomVisiblePage = internalListState.visiblePagesIds.min();

  if (bottomVisiblePage <= 1) {
    return;
  }

  const newBottomPageIndex = bottomVisiblePage - 1;
  let newVisiblePagesIds = internalListState.visiblePagesIds.add(newBottomPageIndex);

  if ((topVisiblePage - newBottomPageIndex) >= MAX_VISIBLE_PAGES) {
    newVisiblePagesIds = newVisiblePagesIds.delete(topVisiblePage);
  }

  // It is necessary to define targetPageOffsetTop before we update the list state
  const scrollPositionUpdate = {
    direction: 'down' as const,
    targetPage: bottomVisiblePage,
    targetPageOffsetTop: pageRefs.current[bottomVisiblePage].offsetTop,
    loadedAllPrevMessages,
  };

  updateListState(
    {
      ...internalListState,
      visiblePagesIds: newVisiblePagesIds,
    },
    scrollPositionUpdate,
  );
};

export const loadPrevPage = ({
  effectiveTimerange,
  finishedScrollPositionUpdateRef,
  internalListState,
  loadedAllPrevMessages,
  loadPrevPromiseRef,
  pageRefs,
  queryId,
  searchTypeId,
  updateListState,
  refreshSearch,
}: {
  effectiveTimerange: LogViewSearchTypeData['effectiveTimerange'],
  finishedScrollPositionUpdateRef: MutableRefObject<boolean>,
  internalListState: LogViewState['listState'],
  loadedAllPrevMessages: boolean,
  loadPrevPromiseRef: MutableRefObject<CancellablePromise<void>>,
  pageRefs: PageRefs,
  queryId: string,
  searchTypeId: LogViewSearchTypeData['id'],
  updateListState: (
    listState: LogViewState['listState'],
    scrollPositionUpdate: LogViewState['scrollPositionUpdate']
  ) => void,
  refreshSearch: RefreshSearch,
}) => {
  if (finishedScrollPositionUpdateRef.current && !loadPrevPromiseRef.current) {
    const prevPageIsInState = _prevPageIsInState(internalListState);

    if (prevPageIsInState) {
      _loadPrevPageFromState({
        internalListState,
        pageRefs,
        updateListState,
        loadedAllPrevMessages,
      });
    }

    if (!prevPageIsInState && !loadedAllPrevMessages) {
      const _appendLoadedMessages = _onAppendLoadedMessages({
        loadedAllPrevMessages,
        internalListState,
        pageRefs,
        updateListState,
      });

      _requestPrevPage({
        refreshSearch,
        appendLoadedMessages: _appendLoadedMessages,
        effectiveTimerange,
        internalListState,
        loadPrevPromiseRef,
        queryId,
        searchTypeId,
      });
    }
  }
};

export const loadNextPage = ({
  internalListState,
  pageRefs,
  updateListState,
  finishedScrollPositionUpdateRef,
  loadedAllPrevMessages,
}: {
  internalListState: LogViewState['listState'],
  loadedAllPrevMessages: boolean,
  pageRefs: PageRefs,
  updateListState: (
    listState: LogViewState['listState'],
    scrollPositionUpdate: LogViewState['scrollPositionUpdate']
  ) => void,
  finishedScrollPositionUpdateRef: MutableRefObject<boolean>,
}) => {
  if (finishedScrollPositionUpdateRef.current) {
    _loadNextPageFromState({
      loadedAllPrevMessages,
      internalListState,
      pageRefs,
      updateListState,
    });
  }
};
