import filterMenuItems, { filterCloudMenuItems } from 'util/conditional/filterMenuItems';
import { IlluminateDropdownBadge, IlluminateLinkBadge } from 'common/components';
import { INSTANT_ARCHIVING_ENABLED } from 'instant-archiving/Constants';
import Permissions from 'security-app/Permissions';

const DEFAULT_PERSPECTIVE_SECURITY_PAGES = [
  { path: '/security/activity', description: 'Activity', permissions: Permissions.securityApp.read },
  { path: '/security/security-events/alerts', description: 'Security Events', permissions: 'security_event:read' },
  { path: '/security/investigations', description: 'Investigations', permissions: 'investigations:read' },
  { path: '/security/anomalies', description: 'Anomalies', permissions: 'anomaly_configuration:read' },
  { path: '/security/sigma', description: 'Sigma Rules', permissions: 'sigma_rules:read' },
  { path: '/security/assets', description: 'Assets', permissions: 'asset:read' },
];

export const SECURITY_PAGES = [
  { path: '/security/activity', description: 'Activity', permissions: Permissions.securityApp.read },
  { path: '/search', description: 'Search' },
  { path: '/dashboards', description: 'Dashboards' },
  { path: '/security/security-events/alerts', description: 'Security Events', permissions: 'security_event:read' },
  { path: '/security/investigations', description: 'Investigations', permissions: 'investigations:read' },
  { path: '/security/anomalies', description: 'Anomalies', permissions: 'anomaly_configuration:read' },
  { path: '/security/sigma', description: 'Sigma Rules', permissions: 'sigma_rules:read' },
  { path: '/security/assets', description: 'Assets', permissions: 'asset:read' },
];

export default {
  navigation: [
    {
      description: 'Enterprise',
      children: filterCloudMenuItems(
        filterMenuItems(
          [
            { path: '/system/enterprise', description: 'Overview', permissions: 'licenseinfos:read' },
            { path: '/system/archives', description: 'Archives', permissions: 'archive:read' },
            { path: '/system/instant-archiving', description: 'Instant Archiving', permissions: 'archive:read' },
            { path: '/system/auditlog', description: 'Audit Log', permissions: 'auditlog_status:read' },
            { path: '/system/licenses', description: 'Licenses', permissions: 'licenses:read' },
            { path: '/reports', description: 'Reports', permissions: 'report:update' },
            { path: '/customization', description: 'Customization', permissions: 'clusterconfig:read' },
            { path: '/system/forwarders', description: 'Forwarders', permissions: 'forwarders:edit' },
            { path: '/illuminate', description: 'Illuminate', permissions: '*', BadgeComponent: IlluminateLinkBadge },
            { path: '/my-filters', description: 'My Search Filters', requiredFeatureFlag: 'search_filter' },
          ],
          INSTANT_ARCHIVING_ENABLED ? [] : ['/system/instant-archiving'],
        ),
        ['/system/licenses', '/system/forwarders'],
      ),
      BadgeComponent: IlluminateDropdownBadge,
    },
    {
      description: 'Security',
      children: DEFAULT_PERSPECTIVE_SECURITY_PAGES,
    },
  ],
};
