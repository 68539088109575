import * as React from 'react';
import styled, { css } from 'styled-components';

import { EmptyList, GLCheckbox } from 'security-app/components/common';
import type { OwnerOptionType } from 'security-app/hooks/api/securityEventsAPI.types';
import type { ColumnFilterData, FilterData } from 'security-app/components/common/Filters/ColumnFilter.types';

const TD = styled.td<{ $main?: boolean }>(({ theme, $main }) => css`
  vertical-align: middle !important;
  max-width: 300px;
  padding: 8px 6px !important;
  color: ${$main ? theme.colors.global.link : theme.colors.brand.tertiary};
  cursor: ${$main ? 'pointer' : 'normal'};
  font-weight: ${$main ? 'bold' : 'regular'};

  &:hover {
    color: ${$main ? theme.colors.global.linkHover : theme.colors.brand.tertiary};
    text-decoration: ${$main ? 'underline' : 'none'};
  }

  &:not(:last-child) {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`);

type Props = {
  ownerOptions: OwnerOptionType[],
  filters: ColumnFilterData,
  ownerId: string,
  setOwnerId: (owner: string) => void,
};

function ListRows({ ownerOptions, filters, ownerId, setOwnerId }: Props) {
  const selectOwner = (newOwner: OwnerOptionType) => () => {
    setOwnerId(newOwner.id);
  };

  const strFilters = Object.entries(filters).reduce((acc: { [key: string]: string }, [key, values]: [string, FilterData[]]) => ({
    ...acc, [key]: values.join(','),
  }), {});

  return ownerOptions.length < 1 ? (
    <EmptyList filters={strFilters}
               noSearchResultText="No User or Teams found for the provided criteria"
               noEntitiesExist="Currently there are no User or Teams to list"
               colSpan={3} />
  ) : (
    <>
      {ownerOptions.map((owner: OwnerOptionType) => (
        <tr key={owner.id}>
          <TD><GLCheckbox checked={owner.id === ownerId} onChange={selectOwner(owner)} /></TD>
          <TD $main onClick={selectOwner(owner)}>{owner.name}</TD>
          <TD>{owner.type}</TD>
        </tr>
      ))}
    </>
  );
}

export default ListRows;
