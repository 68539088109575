import React, { useCallback } from 'react';

import type { InstantArchivingConfig } from 'instant-archiving/Types';
import InstantArchivingConfigActions from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigOverview/InstantArchivingConfigActions';
import BulkActions from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigOverview/BulkActions';

const useTableElements = () => {
  const entityActions = useCallback((archiveConfig: InstantArchivingConfig) => (
    <InstantArchivingConfigActions archiveConfig={archiveConfig} />
  ), []);

  return {
    entityActions,
    bulkActions: <BulkActions />,
  };
};

export default useTableElements;
