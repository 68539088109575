import * as React from 'react';
import { useState, useEffect } from 'react';

import useLocation from 'routing/useLocation';
import useLicenseCheck from 'license/useLicenseCheck';
import withIsPermitted from 'forwarder/util/withIsPermitted';
import StringUtils from 'util/StringUtils';
import { DocumentTitle, Spinner, IfPermitted } from 'components/common';
import useSearchVersionCheck from 'hooks/useSearchVersionCheck';
import useActivePerspective from 'components/perspectives/hooks/useActivePerspective';
import { Container, SideNav, ContentArea, SideNavItem } from 'components/security/page-layout';
import SecurityTeaserPage from 'components/security/teaser/SecurityPage';
import type { IconName } from 'components/common/Icon';
import Permissions from 'security-app/Permissions';
import Routes from 'routing/Routes';
import SecurityRoutes from 'security-app/routing/Routes';

const REQUIRED_PERMISSIONS = [Permissions.securityApp.read];

export type NavItem = {
  path: string;
  iconName?: IconName;
  title: string;
  end: boolean;
  permissions: string[];
};

export const navItems: NavItem[] = [
  {
    path: Routes.SECURITY.ACTIVITY,
    iconName: 'ballot',
    title: 'Activity',
    end: false,
    permissions: [Permissions.securityApp.read],
  },
  {
    path: SecurityRoutes.SECURITY_EVENTS.ALERTS,
    iconName: 'notifications',
    title: 'Security Events',
    end: false,
    permissions: ['security_event:read'],
  },
  {
    path: SecurityRoutes.INVESTIGATIONS.LIST,
    iconName: 'extension',
    title: 'Investigations',
    end: false,
    permissions: ['investigations:read'],
  },
  {
    path: SecurityRoutes.ANOMALIES.LIST,
    iconName: 'zoom_in',
    title: 'Anomalies',
    end: false,
    permissions: ['anomaly_configuration:read'],
  },
  {
    path: SecurityRoutes.SIGMA.LIST,
    iconName: 'straighten',
    title: 'Sigma Rules',
    end: false,
    permissions: ['sigma_rules:read'],
  },
  {
    path: SecurityRoutes.ASSETS.LIST,
    iconName: 'computer',
    title: 'Assets',
    end: false,
    permissions: ['asset:read'],
  },
];

const SecurityLayout = ({ children }: React.PropsWithChildren) => {
  const { activePerspective } = useActivePerspective();
  const isSecurityPerspective = activePerspective.id === 'security';
  const [showSideBar, setShowSideBar] = useState(!isSecurityPerspective);
  const [title, setTitle] = useState('security');
  const { security: { isValid: isValidSecurityLicense } } = useLicenseCheck();
  const location = useLocation();
  const { isLoading: isLoadingSearchVersion } = useSearchVersionCheck('opensearch');

  useEffect(() => {
    const searchPattern = /\/security\//;
    const newLocation: string = location.pathname.replace(searchPattern, '');

    if (newLocation === '/security') {
      setTitle('Security');
    } else {
      setTitle(StringUtils.toTitleCase(newLocation, '-'));
    }
  }, [location]);

  if (isLoadingSearchVersion) {
    return (
      <Spinner text="Loading security module ..." />
    );
  }

  if (!isValidSecurityLicense) {
    return (
      <SecurityTeaserPage />
    );
  }

  return (
    <DocumentTitle title={title}>
      <Container>
        {!isSecurityPerspective && (
          <SideNav isOpen={showSideBar} toggleIsOpen={() => setShowSideBar((cur) => !cur)}>
            {navItems.map((route) => (
              <IfPermitted key={route.title} permissions={route.permissions}>
                <SideNavItem iconName={route.iconName}
                             linkTarget={route.path}
                             linkEnd={route.end}>
                  {route.title}
                </SideNavItem>
              </IfPermitted>
            ))}
          </SideNav>
        )}
        <ContentArea $sideNavIsOpen={!isSecurityPerspective && showSideBar} $isSecurityPerspective={isSecurityPerspective}>
          {children}
        </ContentArea>
      </Container>
    </DocumentTitle>
  );
};

export default withIsPermitted(SecurityLayout, REQUIRED_PERMISSIONS);
