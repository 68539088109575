import * as React from 'react';
import styled, { css } from 'styled-components';

const TH = styled.th(({ theme }) => css`
  vertical-align: middle !important;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px 6px !important;
  border-bottom: 2px solid ${theme.colors.gray[80]} !important;
  background-color: ${theme.colors.table.head.background} !important;
`);

function ListHeader() {
  return (
    <>
      <colgroup>
        <col style={{ width: 32 }} />
        <col style={{ width: '32%' }} />
        <col style={{ width: '32%' }} />
        <col style={{ width: 'auto' }} />
      </colgroup>
      <thead>
        <tr>
          <TH />
          <TH>Title</TH>
          <TH>Description</TH>
          <TH>Type</TH>
        </tr>
      </thead>
    </>
  );
}

export default ListHeader;
