import Reflux from 'reflux';

import type { Archive, FileNames, PaginatedArchives } from 'archive/types';
import type { AvailabilityResponse } from 'archive/ArchiveStore';

type ActionsType = {
  searchPaginated: (page: number, perPage: number, query: string, isPeriodicalRequest?: boolean) => Promise<PaginatedArchives>,
  createArchive: (indexName: string) => Promise<unknown>,
  restoreArchive: (backendId: string, archiveId: string) => Promise<unknown>,
  restoreArchives: (archivesToRestore: Array<Archive>) => Promise<unknown>,
  deleteArchive: (backendId: string, archiveId: string) => Promise<unknown>,
  rebuildCatalog: () => Promise<unknown>,
  availability: (entryId: string) => Promise<AvailabilityResponse>,
  checkOutputTemplate: (template: string) => Promise<{ result: string }>,
  exportFilenames: (query: string) => Promise<FileNames>,
}

const ArchiveActions = Reflux.createActions<ActionsType>({
  searchPaginated: { asyncResult: true },
  createArchive: { asyncResult: true },
  restoreArchive: { asyncResult: true },
  restoreArchives: { asyncResult: true },
  deleteArchive: { asyncResult: true },
  rebuildCatalog: { asyncResult: true },
  availability: { asyncResult: true },
  checkOutputTemplate: { asyncResult: true },
  exportFilenames: { asyncResult: true },
});

export default ArchiveActions;
