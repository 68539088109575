import * as React from 'react';

import type { WidgetComponentProps } from 'views/types';

import type { InvestigationsListResult } from './types';
import InvestigationsList from './InvestigationsList';
import InvestigationsNumber from './InvestigationsNumber';
import { LIST_MODE, NUMBER_MODE } from './logic/InvestigationsWidgetConfig';
import type InvestigationsListConfig from './logic/InvestigationsWidgetConfig';

const InvestigationsVisualization = (props: WidgetComponentProps<InvestigationsListConfig, InvestigationsListResult>) => {
  const { config } = props;

  if (config.mode === LIST_MODE) {
    return <InvestigationsList {...props} />;
  }

  if (config.mode === NUMBER_MODE) {
    return <InvestigationsNumber {...props} />;
  }

  return <div>Unknown widget visualization type {config.mode ?? 'undefined'}</div>;
};

export default InvestigationsVisualization;
