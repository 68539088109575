import * as React from 'react';

import useCurrentUser from 'hooks/useCurrentUser';
import BootstrapModalConfirm from 'components/bootstrap/BootstrapModalConfirm';
import { Input, Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import Routes from 'routing/Routes';
import { useAddEvidence, useRemoveEvidence } from 'security-app/hooks/useInvestigationsAPI';
import type { InvestigationsDetailsAPIType, SearchEvidenceType } from 'security-app/hooks/api/investigationsAPI.types';
import useHistory from 'routing/useHistory';

import {
  Row,
  FormGroup,
  EvidenceList,
  EvidenceRow,
  IconButton,
  StyledLink,
} from '../styled.components';

type Props = {
  investigation: InvestigationsDetailsAPIType,
};

function Searches({ investigation }: Props) {
  const { permissions } = useCurrentUser();
  const [newSearchId, setNewSearchId] = React.useState<string>('');
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
  const [deleteSearchId, setDeleteSearchId] = React.useState<string>(null);
  const history = useHistory();

  const canManageEvidence = React.useMemo(() => (
    !investigation.archived
      && (permissions.includes('investigations:edit') || permissions.includes('*'))
  ), [investigation.archived, permissions]);

  const { addEvidence } = useAddEvidence();
  const { removeEvidence, removingEvidence } = useRemoveEvidence();

  const isFormValid = React.useMemo(() => (
    newSearchId && newSearchId.replace(/\s/g, '').length > 0
  ), [newSearchId]);

  const onAddSearch = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault();

    if (isFormValid) {
      await addEvidence({ invId: investigation.id, payload: { searches: [newSearchId] } });
    }

    setNewSearchId('');
  };

  const removeSearch = async () => {
    await removeEvidence({ invId: investigation.id, payload: { searches: [deleteSearchId] } });
    setShowDeleteDialog(false);
    setDeleteSearchId(null);
  };

  const onDelete = (searchId: string) => (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    setShowDeleteDialog(true);
    setDeleteSearchId(searchId);
  };

  const onCancelDelete = () => {
    setShowDeleteDialog(false);
    setDeleteSearchId(null);
  };

  return (
    <>
      {canManageEvidence && (
        <form onSubmit={onAddSearch}>
          <Row>
            <FormGroup>
              <Input id="add-search-id"
                     type="text"
                     label="Add Search"
                     data-testid="add-search-id"
                     autoComplete="off"
                     placeholder="Enter search ID"
                     value={newSearchId}
                     onChange={(e: React.BaseSyntheticEvent) => setNewSearchId(e.target.value)} />
            </FormGroup>
            <Button type="submit"
                    bsStyle="success"
                    onClick={onAddSearch}
                    disabled={!newSearchId}>
              Add
            </Button>
          </Row>
        </form>
      )}
      <EvidenceList>
        {investigation.searches.length === 0
          ? <div style={{ color: 'gray' }}>There are no searches to list</div>
          : investigation.searches.map((search: SearchEvidenceType) => (
            <EvidenceRow key={search.id} $fullWidth $gap="0">
              {canManageEvidence && (
                <IconButton $danger
                            data-testid={`delete-${search.id}`}
                            onClick={onDelete(search.id)}>
                  <Icon name="close" />
                </IconButton>
              )}
              <StyledLink onClick={() => history.push(Routes.VIEWS.VIEWID(search.id))}>
                {search.title}
              </StyledLink>
            </EvidenceRow>
          ))}
      </EvidenceList>
      <BootstrapModalConfirm showModal={showDeleteDialog}
                             title="Delete Search"
                             onConfirm={removeSearch}
                             onCancel={onCancelDelete}
                             confirmButtonDisabled={removingEvidence}>
        Are you sure you want to delete this Search?
      </BootstrapModalConfirm>
    </>
  );
}

export default Searches;
