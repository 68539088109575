import * as React from 'react';
import { useMemo } from 'react';

import { SECURITY_EVENT_STATUSES } from 'security-app/hooks/api/securityEventsAPI.types';
import { Select } from 'components/common';

type Props = {
  onSelect: (value: string) => void,
  selectedValues: Array<string>,
  value: string,
}

const StatusFilter = ({ value, onSelect, selectedValues }: Props) => {
  const statusesOptions = useMemo(() => Object.keys(SECURITY_EVENT_STATUSES).map((status) => ({
    label: SECURITY_EVENT_STATUSES[status],
    value: status,
    disabled: selectedValues.includes(status),
  })), [selectedValues]);

  return (
    <Select placeholder="Select a status"
            clearable={false}
            menuIsOpen
            options={statusesOptions}
            onChange={onSelect}
            value={value} />
  );
};

export default StatusFilter;
