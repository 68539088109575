const DEFAULT_SETTINGS = {
  /*
  fieldId: { // Same ID as supplied to <Input />
    value: '',
    defaultValue: '', // Update StepReview.jsx & relevant step(s) if you need to output
  }
  */

  /* Default Advanced Settings */

  throttleEnabled: {
    value: false, // We want to default to true on render, but never compare the default
  },
  subscribeToAllLog: {
    value: true, // We want to default to true on render, but never compare the default
  },

  symantecEDR: {
    value: '',
  },

  symantecEDRThrottleEnabled: {
    value: undefined,
  },

  pollingInterval: {
    value: 5,
  },
  content_type: {
    value: '',
  },
  availableContents: {
    value: 'events, incidents',
  },
  disableTLSCheck: {
    value: false,
  },
};

export default DEFAULT_SETTINGS;
