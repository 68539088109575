import type { PluginExports } from 'graylog-web-plugin/plugin';

import {
  CreateInvestigationNotificationDetails,
  CreateInvestigationNotificationForm,
  CreateInvestigationNotificationSummary,
} from 'security-app/components/Investigations/notifications';
import { PaginationProvider } from 'security-app/components/common/contexts';
import useLicenseCheck from 'license/useLicenseCheck';
import SecurityBrand from 'security-app/components/SecurityBrand';
import SecurityPage from 'security-app/SecurityPage';
import Routes from 'security-app/routing/Routes';
import useCurrentUser from 'hooks/useCurrentUser';
import { isPermitted } from 'util/PermissionsMixin';
import Permissions from 'security-app/Permissions';
import entityRoutesResolver from 'security-app/routes/entityRoutesResolver';

import investigationsPlugin from './components/Investigations/plugin';
import assetsPlugin from './components/Assets/plugin';
import { InvestigationDrawerProvider } from './components/Investigations/contexts';

import { SECURITY_PAGES } from '../EnterpriseMenuBindings';

const SECURITY_PERSPECTIVE_ID = 'security';

const createInvestigationNotificationDefaultConfig = {
  create_new_investigation: false,
  investigation_assigned_to: null,
  investigation_id: null,
  investigation_priority: null,
  investigation_status: null,
  type: 'create-investigation-notification-v1',
};

const pluginExports: PluginExports = {
  navigation: SECURITY_PAGES.map((page) => ({ ...page, perspective: SECURITY_PERSPECTIVE_ID })),
  entityRoutes: [entityRoutesResolver],
  globalContextProviders: [
    InvestigationDrawerProvider,
    PaginationProvider,
  ],
  eventNotificationTypes: [
    {
      type: 'create-investigation-notification-v1',
      displayName: 'Create Investigation Notification [Security]',
      formComponent: CreateInvestigationNotificationForm,
      summaryComponent: CreateInvestigationNotificationSummary,
      detailsComponent: CreateInvestigationNotificationDetails,
      defaultConfig: createInvestigationNotificationDefaultConfig,
    },
  ],
  perspectives: [
    {
      id: SECURITY_PERSPECTIVE_ID,
      title: 'Security',
      brandComponent: SecurityBrand,
      welcomeRoute: Routes.WELCOME,
      useCondition: () => {
        const { security: { isValid: isValidSecurityLicense } } = useLicenseCheck();
        const { permissions } = useCurrentUser();

        return isPermitted(permissions, Permissions.securityApp.read) && isValidSecurityLicense;
      },
    },
  ],
  securityPage: [SecurityPage],
  ...investigationsPlugin,
  ...assetsPlugin,
};

export default pluginExports;
