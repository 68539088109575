import React, { createContext, useContext, useState } from 'react';

const PaginationContext = createContext(null);
const PaginationUpdateContext = createContext(null);

type Props = {
  initPage?: number;
  initPerPage?: number;
  initFilters?: { [key: string]: string };
  children: React.ReactNode
};

export function PaginationProvider({ initPage, initPerPage, initFilters, children }: Props) {
  const [localPagination, setLocalPagination] = useState({
    page: initPage,
    perPage: initPerPage,
    query: undefined,
    orderBy: undefined,
    direction: undefined,
    filters: initFilters,
  });

  return (
    <PaginationContext.Provider value={localPagination}>
      <PaginationUpdateContext.Provider value={setLocalPagination}>
        {children}
      </PaginationUpdateContext.Provider>
    </PaginationContext.Provider>
  );
}

PaginationProvider.defaultProps = {
  initPage: 1,
  initPerPage: 10,
  initFilters: {},
};

export function usePagination() {
  return useContext(PaginationContext);
}

export function useSetPagination() {
  return useContext(PaginationUpdateContext);
}
