import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/common';
import { Button } from 'components/bootstrap';

interface Props {
  className?: string;
  onToggle: (visible: boolean) => void;
  title: string;
  visible: boolean;
}
const AdditionalFieldsContent = styled.div<{ $visible?: boolean }>(({ $visible }) => css`
  display: ${$visible ? 'block' : 'none'};
  padding: 0 100px 0 25px;
`);

const ToggleAdditionalFields = styled(Button)`
  border: 0;
  display: block;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

const AdditionalFields = ({
  children,
  className,
  onToggle = () => {},
  title,
  visible = false,
}: React.PropsWithChildren<Props>) => {
  const [fieldsVisible, setFieldsVisible] = useState<boolean>(visible);

  const handleToggle = () => {
    setFieldsVisible(!fieldsVisible);
    onToggle(!fieldsVisible);
  };

  return (
    <div className={className}>
      <ToggleAdditionalFields bsStyle="link"
                              bsSize="xsmall"
                              onClick={handleToggle}
                              type="button">
        {title}{' '}
        <Icon name={fieldsVisible ? 'expand_more' : 'chevron_right'} />
      </ToggleAdditionalFields>

      <AdditionalFieldsContent $visible={fieldsVisible}>
        {children}
      </AdditionalFieldsContent>
    </div>
  );
};

AdditionalFields.defaultProps = {
  className: undefined,
};

export default AdditionalFields;
