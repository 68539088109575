import SigmaEventSummary from './Summary';
import type { SigmaConfigType } from './eventSigma.types';

const defaultConfig: SigmaConfigType = {
  search_within_ms: 5 * 60 * 1000,
  execute_every_ms: 5 * 60 * 1000,
  type: 'sigma-v1',
  filters: [],
  streams: [],
  sigma_rule_id: '',
  _is_schedule: true,
};

export default {
  eventDefinitionTypes: [
    {
      type: 'sigma-v1',
      displayName: 'Sigma Events',
      sortOrder: 1, // Sort after conditions working on log messages
      description: 'Events created by Sigma rules. Sigma is a generic and open signature format that allows you to describe relevant log events in a straight forward manner.',
      formComponent: null,
      summaryComponent: SigmaEventSummary,
      defaultConfig: defaultConfig,
    },
  ],
};
