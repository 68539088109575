import * as React from 'react';

import { Table } from 'components/bootstrap';
import { Spinner } from 'components/common';
import { FiltersRow } from 'security-app/components/common';
import { useGetUsersNTeams } from 'security-app/hooks/useSecurityEventsAPI';
import type { ColumnFilterData } from 'security-app/components/common/Filters/ColumnFilter.types';
import type { OwnerOptionType } from 'security-app/hooks/api/securityEventsAPI.types';

import ListHeader from './ListHeader';
import ListRows from './ListRows';

type Props = {
  ownerId: string,
  setOwnerId: (owner: string) => void,
};

function List({ ownerId, setOwnerId }: Props) {
  const { usersNTeams, loadingUsersNTeams } = useGetUsersNTeams();
  const [options, setOptions] = React.useState<OwnerOptionType[]>(usersNTeams);
  const [filters, setFilters] = React.useState<ColumnFilterData>({});

  React.useEffect(() => setOptions(usersNTeams), [usersNTeams]);

  const removeFilter = (filterKey: string) => (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    const auxFilters = { ...filters };
    delete auxFilters[filterKey];

    if (Object.keys(auxFilters).length) {
      setOptions(
        usersNTeams.filter((option: OwnerOptionType) => (
          Object.values(auxFilters).flat().map(({ value }) => value).includes(option[filterKey])
        )),
      );
    } else {
      setOptions(usersNTeams);
    }

    setFilters(auxFilters);
  };

  return (
    <>
      <FiltersRow filters={filters} onRemove={removeFilter} />
      <Table condensed hover className="table table-striped" data-testid="open-investigations-list">
        <ListHeader ownerOptions={usersNTeams}
                    filters={filters}
                    setFilters={setFilters}
                    setCurrentOptions={setOptions} />
        <tbody>
          {loadingUsersNTeams ? <tr><td colSpan={2} aria-label="Spinner"><Spinner text="Loading Users and Teams ..." /></td></tr> : (
            <ListRows ownerOptions={options}
                      filters={filters}
                      setOwnerId={setOwnerId}
                      ownerId={ownerId} />
          )}
        </tbody>
      </Table>
    </>
  );
}

export default List;
