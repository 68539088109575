import * as React from 'react';
import styled, { css } from 'styled-components';

import type { EventNotification } from 'stores/event-notifications/EventNotificationsStore';
import { EmptyList, GLCheckbox } from 'security-app/components/common';

const TD = styled.td<{ $main?: boolean; }>(({ theme, $main }) => css`
  vertical-align: middle !important;
  max-width: 300px;
  padding: 8px 6px !important;
  color: ${$main ? theme.colors.global.link : theme.colors.brand.tertiary};
  cursor: ${$main ? 'pointer' : 'normal'};
  font-weight: ${$main ? 'bold' : 'regular'};

  &:hover {
    color: ${$main ? theme.colors.global.linkHover : theme.colors.brand.tertiary};
    text-decoration: ${$main ? 'underline' : 'none'};
  }

  &:not(:last-child) {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`);

type Props = {
  notificationOptions: EventNotification[],
  notificationId: string,
  setNotificationId: (notification: string) => void,
};

function ListRows({ notificationOptions, notificationId, setNotificationId }: Props) {
  const selectNotification = (newNotification: EventNotification) => () => {
    setNotificationId(newNotification.id);
  };

  return notificationOptions.length < 1 ? (
    <EmptyList noSearchResultText="No Notifications found for the provided criteria"
               noEntitiesExist="Currently there are no Notifications to list"
               colSpan={4} />
  ) : (
    <>
      {notificationOptions.map((notification: EventNotification) => (
        <tr key={notification.id}>
          <TD><GLCheckbox checked={notification.id === notificationId} onChange={selectNotification(notification)} /></TD>
          <TD $main onClick={selectNotification(notification)}>{notification.title}</TD>
          <TD>{notification.description}</TD>
          <TD>{notification.config?.type}</TD>
        </tr>
      ))}
    </>
  );
}

export default ListRows;
