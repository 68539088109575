import * as React from 'react';

import { ConfirmDialog } from 'security-app/components/common/Modal';
import { useModalContext } from 'security-app/components/common/Modal/ModalContext';
import { useUpdateSecurityEvent } from 'security-app/hooks/useSecurityEventsAPI';

function ConfirmOwnerRemove() {
  const { entity: securityEvent, setEntity, setModal } = useModalContext();
  const { updateSecurityEvent, updatingSecurityEvent } = useUpdateSecurityEvent();

  const onConfirm = async () => {
    const payload = {
      owner: null,
      notes: securityEvent.notes,
      status: securityEvent.status,
    };

    await updateSecurityEvent({ id: securityEvent.event_id, data: payload });

    setModal(null);
    setEntity(null);
  };

  const onCancel = () => {
    setModal(null);
    setEntity(null);
  };

  return (
    <ConfirmDialog show
                   onConfirm={onConfirm}
                   confirmDisabled={updatingSecurityEvent}
                   isLoading={updatingSecurityEvent}
                   confirmText="Confirm"
                   onCancel={onCancel}
                   title="Delete Event Owner">
      <p>Are you sure you want to delete the current owner?</p>
    </ConfirmDialog>
  );
}

export default ConfirmOwnerRemove;
