import * as React from 'react';
import { useCallback } from 'react';

import { useModalContext } from 'security-app/components/common/Modal/ModalContext';
import DefinitionFormModal from 'security-app/components/SecurityEvents/Definitions/DefinitionFormModal';

function EventDefinitionCreateHOC() {
  const { setModal, setEntity } = useModalContext();

  const onClose = useCallback(() => {
    setModal(null);
    setEntity(null);
  }, [setEntity, setModal]);

  return (
    <DefinitionFormModal onClose={onClose} action="create" onSubmit={onClose} onCancel={onClose} />
  );
}

export default EventDefinitionCreateHOC;
