import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Badge } from 'components/bootstrap';
import CustomizationContext from 'contexts/CustomizationContext';
import CustomizationActions from 'actions/customizations/CustomizationActions';

const CUSTOMIZATION_HEADER_BADGE = 'org.graylog.plugins.customization.HeaderBadge';

const StyledBadge = styled(Badge)<{ $color: string }>(({ $color, theme }) => css`
  background-color: ${$color};
  color: ${theme.utils.readableColor($color)};
`);

type Props = {
  smallScreen: boolean,
};

const HeaderBadge = ({ smallScreen = false }: Props) => {
  const smallScreenClass = smallScreen ? 'small-scrn-badge' : '';
  const badgeConfig = useContext(CustomizationContext)[CUSTOMIZATION_HEADER_BADGE];

  useEffect(() => {
    CustomizationActions.get(CUSTOMIZATION_HEADER_BADGE);
  }, []);

  if (badgeConfig?.badge_enable) {
    return (
      <StyledBadge $color={badgeConfig.badge_color as string}
                   className={`${smallScreenClass} dev-badge`}>
        {badgeConfig.badge_text}
      </StyledBadge>
    );
  }

  return null;
};

HeaderBadge.propTypes = {
  smallScreen: PropTypes.bool,
};

HeaderBadge.defaultProps = {
  smallScreen: false,
};

export default HeaderBadge;
