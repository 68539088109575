import * as React from 'react';

function useModal(): [boolean, (state: boolean) => void] {
  const [show, setShow] = React.useState(false);

  const toggle = (state: boolean) => setShow(state);

  return [show, toggle];
}

export default useModal;
