const DEFAULT_SETTINGS = {
  /*
  fieldId: { // Same ID as supplied to <Input />
    value: '',
    defaultValue: '', // Update StepReview.jsx & relevant step(s) if you need to output
  }
  */

  /* Default Advanced Settings */

  throttleEnabled: {
    value: false, // We want to default to true on render, but never compare the default
  },
  subscribeToAllLog: {
    value: true, // We want to default to true on render, but never compare the default
  },

  symantecSESName: {
    value: '',
  },

  symantecSESThrottleEnabled: {
    value: undefined,
  },

  noOfChannels: {
    value: 1,
  },

  streamGUID: {
    value: ' ',
  },

  pollingInterval: {
    value: 5,
  },
  contentTypes: {
    value: '',
  },
  availableContents: {
    value: 'events, incidents',
  },
  streamConnectionTimeout: {
    value: 2,
  },
  checkpointIntervalSeconds: {
    value: 30,
  },
};

export default DEFAULT_SETTINGS;
