import React from 'react';

import type { DataTieringConfig } from 'components/indices/data-tiering';
import { durationToRoundedDays } from 'components/indices/data-tiering';

import WarmTierLicenseStatus from './WarmTierLicenseStatus';

type Props = {
  config: DataTieringConfig
}

const TierSummary = ({ config } : Props) => (
  <div>
    <dl>
      <dt>Warm Tier:</dt>
      <dd>{config.warm_tier_enabled ? 'Enabled' : 'Disabled'}</dd>
      {config.warm_tier_enabled && (
        <>
          <dt>Repository:</dt>
          <dd>{config.warm_tier_repository_name}</dd>
          <dt>Min. in Hot Tier:</dt>
          <dd>{durationToRoundedDays(config.index_hot_lifetime_min)} days</dd>
        </>
      )}
      <dt>Archive before deletion:</dt>
      <dd>{config.archive_before_deletion ? 'Enabled' : 'Disabled'}</dd>
    </dl>
    {config.warm_tier_enabled && (<WarmTierLicenseStatus />)}
  </div>
);

export default TierSummary;
