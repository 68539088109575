import * as React from 'react';
import type * as Immutable from 'immutable';
import styled, { css } from 'styled-components';

import IfInteractive from 'views/components/dashboard/IfInteractive';
import { IfPermitted, Timestamp } from 'components/common';
import useViewInvestigation from 'security-app/components/Investigations/hooks/useViewInvestigation';
import type { Assignee } from 'security-app/components/Investigations/hooks/useUsersAndTeams';
import AssigneeCell from 'security-app/components/Investigations/AssigneeCell';
import type { PriorityAPIType } from 'security-app/hooks/api/investigationsAPI.types';
import RowActionMenu from 'security-app/components/Investigations/RowActionMenu';

import type { InvestigationListItem } from '../types';

const columnRenderersByType = {
  date: (date: string) => <Timestamp dateTime={date} />,
};

const Td = styled.td(({ theme }) => css`
  && {
    border-color: ${theme.colors.table.row.border};
  }
`);

const Name = styled.button(({ theme }) => css`
  background: none;
  border: none;
  padding: 0;
  color: ${theme.colors.global.link};

  &:hover {
    color: ${theme.colors.global.linkHover};
    text-decoration: underline;
  }
`);

const useColumnRenderers = (investigationId: string, usersAndTeams: Record<string, Assignee>, priorities: Array<PriorityAPIType>) => {
  const viewInvestigation = useViewInvestigation();

  return ({
    created_at: columnRenderersByType.date,
    updated_at: columnRenderersByType.date,
    name: (name: string) => (
      <Name onClick={() => viewInvestigation(investigationId)} type="button">
        {name}
      </Name>
    ),
    assigned_to: (assigneeId: string) => usersAndTeams[assigneeId] && <AssigneeCell assignee={usersAndTeams[assigneeId]} />,
    priority: (value: number) => (value ? priorities.find((prio) => prio.priority === value)?.text : ''),
  });
};

type Props = {
  investigation: InvestigationListItem,
  fields: Immutable.OrderedSet<string>,
  usersAndTeams: Record<string, Assignee>,
  priorities: Array<PriorityAPIType>
  onArchive: (investigationId: string) => void,
  onDelete: (investigationId: string) => void,
}

const InvestigationsTableRow = ({ investigation, fields, usersAndTeams, priorities, onArchive, onDelete }: Props) => {
  const columnRenderers = useColumnRenderers(investigation.id, usersAndTeams, priorities);

  return (
    <tr key={investigation.id}>
      {fields.toArray().map((field) => {
        const value = investigation[field];
        const columnRenderer = columnRenderers[field];

        return (
          <Td key={field}>
            {columnRenderer ? columnRenderer(value) : value}
          </Td>
        );
      })}
      <IfInteractive>
        <IfPermitted permissions="investigations:edit">
          <Td>
            <RowActionMenu investigation={investigation}
                           onArchive={onArchive}
                           onDelete={onDelete} />
          </Td>
        </IfPermitted>
      </IfInteractive>
    </tr>
  );
};

export default InvestigationsTableRow;
