import * as React from 'react';
import { useCallback } from 'react';

import { Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import SearchFiltersOverviewPopover from 'search-filter/components/search-filter-bar/SearchFiltersOverviewPopover';

type Props = {
  showPopover: boolean,
  onChange: (newState?: boolean) => void,
}

const SearchFiltersOverview = ({ showPopover, onChange }: Props) => {
  const onClose = useCallback(() => onChange(false), [onChange]);
  const onClick = useCallback(() => onChange(), [onChange]);

  return (
    <SearchFiltersOverviewPopover show={showPopover}
                                  onClose={onClose}>
      <Button title="Load filter" onClick={onClick} bsSize="small">
        <Icon name="folder" type="regular" />
      </Button>
    </SearchFiltersOverviewPopover>
  );
};

export default SearchFiltersOverview;
