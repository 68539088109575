// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wA2uy1s9UUhjqLk1t76k {
    width: 150px;
}

.FuLrxyqWosZSwWbpPeMd:not(:last-child)::after {
    content: ', ';
}
`, "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/components/event-definitions/event-definition-form/FieldsList.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[":local(.actions) {\n    width: 150px;\n}\n\n:local(.providerOptions):not(:last-child)::after {\n    content: ', ';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `wA2uy1s9UUhjqLk1t76k`,
	"providerOptions": `FuLrxyqWosZSwWbpPeMd`
};
export default ___CSS_LOADER_EXPORT___;
