import * as React from 'react';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';

import { Spinner } from 'components/common';
import { Button } from 'components/bootstrap';
import { Modal } from 'security-app/components/common/Modal';
import { useModalContext } from 'security-app/components/common/Modal/ModalContext';
import { useGetEventDefinitionDetails } from 'security-app/hooks/useSecurityEventsAPI';
import useCreateViewForEvent from 'views/logic/views/UseCreateViewForEvent';
import useCreateSearch from 'views/hooks/useCreateSearch';
import EventInfoBar from 'components/event-definitions/replay-search/EventInfoBar';
import SearchPageLayoutProvider from 'views/components/contexts/SearchPageLayoutProvider';
import SearchPage from 'views/pages/SearchPage';
import type { EventDefinitionAggregation } from 'hooks/useEventDefinition';
import type { EventDefinition } from 'components/event-definitions/event-definitions-types';

const transformExpressionsToArray = ({ series, conditions }): Array<EventDefinitionAggregation> => {
  const res = [];

  const rec = (expression: any) => {
    if (!expression) {
      return 'No condition configured';
    }

    switch (expression.expr) {
      case 'number':
        return ({ value: expression.value });
      case 'number-ref':
        // eslint-disable-next-line no-case-declarations
        const numberRefSeries = series.find((s) => s.id === expression.ref);

        return (numberRefSeries?.type
          ? { field: `${numberRefSeries.type}(${numberRefSeries.field || ''})` }
          : null);
      case '&&':
      case '||':
        return [rec(expression.left), rec(expression.right)];
      case 'group':
        return [rec(expression.child)];
      case '<':
      case '<=':
      case '>':
      case '>=':
      case '==':
        // eslint-disable-next-line no-case-declarations
        const { ref } = expression.left;
        // eslint-disable-next-line no-case-declarations
        const selectedSeries = series.find((s) => s.id === ref);
        // eslint-disable-next-line no-case-declarations
        const fnSeries = selectedSeries?.type ? `${selectedSeries.type}(${selectedSeries.field || ''})` : undefined;
        res.push({ expr: expression.expr, value: expression.right.value, function: selectedSeries?.type, fnSeries, field: selectedSeries?.field });

        return [rec(expression.left), rec(expression.right)];
      default:
        return null;
    }
  };

  rec(conditions.expression);

  return res;
};

const eventDefinitionDataMapper = (eventDefinition: EventDefinition): { eventDefinition: EventDefinition, aggregations: Array<EventDefinitionAggregation> } => ({
  eventDefinition: eventDefinition,
  aggregations: (eventDefinition?.config?.series && eventDefinition?.config?.conditions)
    ? uniqWith(transformExpressionsToArray({ series: eventDefinition.config.series, conditions: eventDefinition.config.conditions }), isEqual)
    : [],
});

function ReplayEvent() {
  const { entity: securityEvent, setEntity, setModal } = useModalContext();
  const { eventDefinitions, loadingEventDefinition } = useGetEventDefinitionDetails([securityEvent.event.event_definition_id]);
  const { aggregations } = eventDefinitionDataMapper(eventDefinitions[0]);

  const _view = useCreateViewForEvent({
    eventData: securityEvent.event,
    eventDefinition: eventDefinitions[0],
    aggregations,
  });
  const view = useCreateSearch(_view);
  const searchPageLayout = React.useMemo(() => ({
    infoBar: { component: EventInfoBar },
  }), []);

  const onCancel = () => {
    setModal(null);
    setEntity(null);
  };

  return (
    <Modal show
           maxWidth="1500px"
           title="Replay Event"
           onClose={onCancel}
           closeOnBackdrop
           buttons={<Button bsStyle="primary" onClick={onCancel}>Close</Button>}>
      {loadingEventDefinition ? <Spinner text="Loading replay data..." /> : (
        <SearchPageLayoutProvider value={searchPageLayout}>
          <SearchPage view={view} isNew />
        </SearchPageLayoutProvider>
      )}
    </Modal>
  );
}

export default ReplayEvent;
