import * as React from 'react';

import { useGetUsers, useGetTeams } from 'security-app/hooks/useInvestigationsAPI';

export type Assignee = { label: string, type: 'user' | 'team' }
export type AssigneeListItem = Record<string, Assignee>;

const useUsersAndTeams = () => {
  const { users } = useGetUsers(true);
  const { teams } = useGetTeams(true);

  return React.useMemo(() => ({
    ...users.reduce<AssigneeListItem>((acc, user) => ({ ...acc, [user.id]: { label: user.full_name, type: 'user' } }), {}),
    ...teams.reduce<AssigneeListItem>((acc, team) => ({ ...acc, [team.id]: { label: team.name, type: 'team' } }), {}),
  }), [users, teams]);
};

export default useUsersAndTeams;
