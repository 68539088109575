import * as React from 'react';

import Timestamp from 'components/common/Timestamp';
import Assignee from 'search/overview-configuration/Assignee';
import Status from 'search/events/Status';
import type { Attributes } from 'views/components/widgets/overview-configuration/filters/types';
import useLicenseCheck from 'license/useLicenseCheck';

const useCondition = () => {
  const { security } = useLicenseCheck();

  return security?.isValid;
};

const eventsAttributes: Attributes = [
  {
    attribute: 'opened_at',
    displayValue: (value: string) => <Timestamp dateTime={value} />,
    sortable: true,
    title: 'Opened At',
    useCondition,
  },
  {
    attribute: 'updated_at',
    displayValue: (value: string) => <Timestamp dateTime={value} />,
    sortable: true,
    title: 'Updated At',
    useCondition,
  },
  {
    attribute: 'closed_at',
    displayValue: (value: string) => <Timestamp dateTime={value} />,
    sortable: true,
    title: 'Closed At',
    useCondition,
  },
  {
    attribute: 'owner',
    displayValue: (value: string) => (value ? <Assignee assigneeId={value} /> : ''),
    sortable: true,
    title: 'Owner',
    useCondition,
  },
  {
    attribute: 'status',
    displayValue: (value: string) => <Status statusId={value} />,
    sortable: true,
    title: 'Status',
    useCondition,
  },
  {
    attribute: 'risk_score',
    title: 'Risk Score',
    sortable: true,
    useCondition,
  },
];

export default eventsAttributes;
