import * as React from 'react';

import Icon from 'components/common/Icon';
import MessagesWidget from 'views/logic/widgets/MessagesWidget';
import type { AppDispatch } from 'stores/useAppDispatch';
import type Widget from 'views/logic/widgets/Widget';
import LogViewWidget from 'logview/logic/LogViewWidget';
import type { WidgetActionType } from 'views/components/widgets/Types';
import { updateWidget } from 'views/logic/slices/widgetActions';

import validLicensePresent from '../license/ValidLicensePresent';

const CreateLogViewFromMessageTable: WidgetActionType = {
  type: 'toggle-log-view',
  action: (
    messageTable: MessagesWidget,
    { widgetFocusContext: { focusedWidget, setWidgetFocusing } },
  ) => (dispatch: AppDispatch) => {
    if (focusedWidget?.id !== messageTable.id) {
      setWidgetFocusing(messageTable.id);
    }

    const logViewWidget = LogViewWidget.fromMessageTable(messageTable);

    return dispatch(updateWidget(messageTable.id, logViewWidget));
  },
  isHidden: (w: Widget) => (w.type !== MessagesWidget.type),
  title: () => <><Icon name="open_with" /> Switch to Log View</>,
  disabled: () => !validLicensePresent(),
};

export default CreateLogViewFromMessageTable;
