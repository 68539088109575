import React from 'react';
import PropTypes from 'prop-types';

import type { LicenseStatus } from 'archive/types';
import { LicenseStatusPropType } from 'archive/propTypes';
import { LinkContainer } from 'components/common/router';
import { OverlayTrigger } from 'components/common';
import { Button } from 'components/bootstrap';
import Routes from 'routing/Routes';

type Props ={
  licenseStatus: LicenseStatus,
  buttonTitle: string,
}

const CreateForwarderButton = ({ licenseStatus, buttonTitle } : Props) => {
  const { status } = licenseStatus;

  return (status && status.valid) ? (
    <LinkContainer to={Routes.pluginRoute('SYSTEM_FORWARDERS_NEW')}>
      <Button bsStyle="success">{buttonTitle}</Button>
    </LinkContainer>
  ) : (
    <OverlayTrigger rootClose
                    overlay={<>Creating new forwarder is disabled because there is no valid license.</>}
                    placement="top">
      <Button bsStyle="success" className="disabled">{buttonTitle}</Button>
    </OverlayTrigger>
  );
};

CreateForwarderButton.propTypes = {
  licenseStatus: LicenseStatusPropType.isRequired,
  buttonTitle: PropTypes.string.isRequired,
};

export default CreateForwarderButton;
