import * as React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';

import { fetchPeriodically } from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import type { Status } from 'license/types';
import Spinner from 'components/common/Spinner';

import LicenseCheckContext from './LicenseCheckContext';

export const LICENSE_STATUS_KEY = 'license_status';

const checkLicenseForSubject = (subject: string) => fetchPeriodically<{ status: Status }>(
  'GET',
  qualifyUrl(`/plugins/org.graylog.plugins.license/licenses/status/for-subject?subject=/license/${subject}&bypass_cache=true`),
).then((response) => !!response?.status?.valid).catch(() => false);

const useLicenseCheckForSubjects = () => {
  const queryOptions = { refetchInterval: 600_000, retry: 0 }; // 10 min refetch interval
  const { data: isValidSecurityLicense = false, isLoading: isLoadingSecurityLicense } = useQuery(
    [LICENSE_STATUS_KEY, 'security'],
    () => checkLicenseForSubject('security'),
    queryOptions,
  );
  const { data: isValidIlluminateLicense = false, isLoading: isLoadingIlluminateLicense } = useQuery(
    [LICENSE_STATUS_KEY, 'illuminate'],
    () => checkLicenseForSubject('enterprise/illuminate'),
    queryOptions,
  );

  return {
    subjects: {
      security: { isValid: isValidSecurityLicense },
      illuminate: { isValid: isValidIlluminateLicense },
    },
    isLoading: isLoadingSecurityLicense || isLoadingIlluminateLicense,
  };
};

type Props = {
  children: React.ReactNode,
}

const LicenseCheckProvider = ({ children }: Props) => {
  const { subjects, isLoading } = useLicenseCheckForSubjects();

  return (
    <LicenseCheckContext.Provider value={subjects}>
      {isLoading ? <Spinner /> : children}
    </LicenseCheckContext.Provider>
  );
};

LicenseCheckProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LicenseCheckProvider;
