import * as React from 'react';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import StringUtils from 'util/StringUtils';
import UserNotification from 'util/UserNotification';
import BulkActionsDropdown from 'components/common/EntityDataTable/BulkActionsDropdown';
import DeleteArchiveConfigAction from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigOverview/BulkActions/DeleteArchiveConfigAction';
import useSelectedEntities from 'components/common/EntityDataTable/hooks/useSelectedEntities';

const BulkActions = () => {
  const { selectedEntities, setSelectedEntities } = useSelectedEntities();
  const queryClient = useQueryClient();
  const selectedItemsAmount = selectedEntities?.length;
  const refetchArchiveConfigs = useCallback(() => queryClient.invalidateQueries(['instant-archiving', 'overview']), [queryClient]);
  const descriptor = StringUtils.pluralize(selectedEntities?.length, 'archive configuration', 'archive configurations');

  const handleFailures = useCallback((failures: Array<{ entity_id: string }>, actionPastTense: string) => {
    if (failures?.length) {
      const notDeletedArchiveIds = failures.map(({ entity_id }) => entity_id);
      setSelectedEntities(notDeletedArchiveIds);
      UserNotification.error(`${notDeletedArchiveIds.length} out of ${selectedItemsAmount} selected ${descriptor} could not be ${actionPastTense}.`);
    } else {
      setSelectedEntities([]);
      UserNotification.success(`${selectedItemsAmount} ${descriptor} ${StringUtils.pluralize(selectedItemsAmount, 'was', 'were')} ${actionPastTense} successfully.`, 'Success');
    }
  }, [descriptor, selectedItemsAmount, setSelectedEntities]);

  return (
    <BulkActionsDropdown>
      <DeleteArchiveConfigAction handleFailures={handleFailures}
                                 selectedArchiveConfigIds={selectedEntities}
                                 refetchArchiveConfigs={refetchArchiveConfigs} />

    </BulkActionsDropdown>
  );
};

export default BulkActions;
