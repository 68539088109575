import React, { useContext, useState } from 'react';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

import { ApiRoutes } from './Routes';

import FormWrap from '../common/FormWrap';
import ValidatedInput from '../common/ValidatedInput';
import { FormDataContext } from '../common/context/FormData';
import formValidation from '../common/utils/formValidation';
import type {
  ErrorMessageType,
  HandleFieldUpdateType,
  FormDataContextType,
  HandleSubmitType,
} from '../common/utils/types';

type StepAuthorizeProps = {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
};

const StepAuthorize: React.FC<StepAuthorizeProps> = ({
  onSubmit,
  onChange,
}) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);

  const { connectionString } = formData;

  const handleSubmit = () => {
    const fetcher = fetch(
      'POST',
      qualifyUrl(ApiRoutes.INTEGRATIONS.SymantecEDR.CHECK_CREDENTIALS),
      {
        management_ip: formData?.managementIP?.value || '',
        client_id: formData?.clientId?.value || '',
        client_secret: formData?.clientSecret?.value || '',
      },
    );

    fetcher
      .then((result: any) => {
        if (result.result === 'valid') {
          setFormError(null);
          onSubmit();
        } else {
          setFormError({
            full_message: result.result,
            nice_message:
              'Unable to connect to Symantec EDR using provided configuration.',
          });
        }
      })
      .catch((err) => {
        setFormError({
          full_message: err.message,
          nice_message:
            'Unable to connect to Symantec EDR using provided configuration.',
        });
      });
  };

  const isFormValid = formValidation.isFormValid(
    ['symantecEDRName', 'managementIP', 'clientId', 'clientSecret'],
    formData,
  );

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Verify Connection &amp; Proceed"
              disabled={isFormValid}
              title=""
              error={formError}
              description="">
      <ValidatedInput className=""
                      id="symantecEDRName"
                      type="text"
                      fieldData={formData.symantecEDRName}
                      onChange={onChange}
                      placeholder="Input Name"
                      label="Input Name"
                      autoComplete="off"
                      help="Select a name of your new input that describes it."
                      defaultValue={connectionString?.value}
                      required />

      <ValidatedInput className=""
                      id="managementIP"
                      type="text"
                      fieldData={formData.managementIP}
                      onChange={onChange}
                      label="Management Server Host"
                      help="The IP address or host name of your Symantec EDR Management server"
                      autoComplete="off"
                      required />

      <ValidatedInput className=""
                      id="clientId"
                      type="text"
                      onChange={onChange}
                      fieldData={formData.clientId}
                      label="Client ID"
                      help="The GUID of the client to which the content belongs."
                      required />

      <ValidatedInput className=""
                      id="clientSecret"
                      type="password"
                      onChange={onChange}
                      fieldData={formData.clientSecret}
                      label="Client Secret"
                      help="A secret string of the user to prove its identity when requesting a token."
                      required />
    </FormWrap>
  );
};

export default StepAuthorize;
