import React, { useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';

import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';
import SecurityRoutes, { SECURITY_VIEW_ID_PREFIX } from 'security-app/routing/Routes';
import {
  Overview,
  UserActivity,
  HostActivity,
  NetworkActivity,
  ActivityPage,
  Anomaly,
  DetectorConfiguration,
  AnomalyDetectionEdit,
  Sigma,
  SigmaConfig,
  Investigations,
  InvestigationDetails,
  InvestigationsConfig,
  MachineAssets,
  UserAssets,
  AssetsConfig,
  AssetSources,
  AssetSourcesView,
  AssetSourcesForms,
  WelcomePage,
  SecurityEventsAlerts,
  SecurityEventsDefinitions,
  SecurityEventsNotifications,
} from 'security-app/pages';
import { SECURITY_PATH, prefixUrl } from 'routing/Routes';

export const RouterRoutes = [
  { path: SecurityRoutes.WELCOME, component: <WelcomePage /> },
  { path: SecurityRoutes.ACTIVITY.OVERVIEW, component: <ActivityPage /> },
  { path: SecurityRoutes.ACTIVITY.USER_ACTIVITY, component: <ActivityPage /> },
  { path: SecurityRoutes.ACTIVITY.HOST_ACTIVITY, component: <ActivityPage /> },
  { path: SecurityRoutes.ACTIVITY.NETWORK_ACTIVITY, component: <ActivityPage /> },
  { path: SecurityRoutes.ACTIVITY.OVERVIEW, entityId: `${SECURITY_VIEW_ID_PREFIX}overview`, component: <Overview /> },
  { path: SecurityRoutes.ACTIVITY.USER_ACTIVITY, entityId: `${SECURITY_VIEW_ID_PREFIX}user-activity`, component: <UserActivity /> },
  { path: SecurityRoutes.ACTIVITY.HOST_ACTIVITY, entityId: `${SECURITY_VIEW_ID_PREFIX}host-activity`, component: <HostActivity /> },
  { path: SecurityRoutes.ACTIVITY.NETWORK_ACTIVITY, entityId: `${SECURITY_VIEW_ID_PREFIX}network-activity`, component: <NetworkActivity /> },
  { path: SecurityRoutes.ANOMALIES.LIST, entityId: `${SECURITY_VIEW_ID_PREFIX}anomalies`, component: <Anomaly /> },
  { path: SecurityRoutes.ANOMALIES.DETECTORS.LIST, component: <DetectorConfiguration /> },
  { path: SecurityRoutes.ANOMALIES.DETECTORS.CREATE, component: <AnomalyDetectionEdit /> },
  { path: SecurityRoutes.ANOMALIES.DETECTORS.edit(':id'), component: <AnomalyDetectionEdit /> },
  { path: SecurityRoutes.SIGMA.LIST, component: <Sigma /> },
  { path: SecurityRoutes.SIGMA.CONFIGURATION, component: <SigmaConfig /> },
  { path: SecurityRoutes.INVESTIGATIONS.LIST, component: <Investigations /> },
  { path: SecurityRoutes.INVESTIGATIONS.details(':id'), component: <InvestigationDetails /> },
  { path: SecurityRoutes.INVESTIGATIONS.show(':id'), component: <Investigations /> },
  { path: SecurityRoutes.INVESTIGATIONS.ARCHIVED, component: <Investigations /> },
  { path: SecurityRoutes.INVESTIGATIONS.CONFIGURATION, component: <InvestigationsConfig /> },
  { path: SecurityRoutes.ASSETS.LIST, component: <MachineAssets /> },
  { path: SecurityRoutes.ASSETS.USERS, component: <UserAssets /> },
  { path: SecurityRoutes.ASSETS.CONFIGURATION, component: <AssetsConfig /> },
  { path: SecurityRoutes.ASSETS.SOURCES.LIST, component: <AssetSources /> },
  { path: SecurityRoutes.ASSETS.SOURCES.show(':sourceType', ':sourceId'), component: <AssetSourcesView /> },
  { path: SecurityRoutes.ASSETS.SOURCES.forms(':sourceType'), component: <AssetSourcesForms />, permissions: 'asset:edit' },
  { path: SecurityRoutes.ASSETS.SOURCES.form(':sourceType', ':sourceId'), component: <AssetSourcesForms />, permissions: 'asset:edit' },
  { path: SecurityRoutes.ASSETS.SOURCES.formMappings(':sourceType', ':sourceId'), component: <AssetSourcesForms />, permissions: 'asset:edit' },
  { path: SecurityRoutes.ASSETS.SOURCES.formMapping(':sourceType', ':sourceId', ':mapId'), component: <AssetSourcesForms />, permissions: 'asset:edit' },
  { path: SecurityRoutes.SECURITY_EVENTS.ALERTS, component: <SecurityEventsAlerts />, permissions: 'security_event:read' },
  { path: SecurityRoutes.SECURITY_EVENTS.DEFINITIONS, component: <SecurityEventsDefinitions />, permissions: ['security_event:edit', 'eventdefinitions:edit'] },
  { path: SecurityRoutes.SECURITY_EVENTS.NOTIFICATIONS, component: <SecurityEventsNotifications />, permissions: ['security_event:edit', 'eventnotifications:edit'] },
];

const qualifiedSecurityPath = prefixUrl(SECURITY_PATH);

const Router = () => {
  const currentUser = useCurrentUser();

  const availableRoutes = useMemo(() => RouterRoutes.filter(({ permissions }) => (
    isPermitted(currentUser.permissions, permissions)
  )), [currentUser.permissions]);

  return (
    <Routes>
      {availableRoutes.map(({ path, component }) => <Route key={path} path={path.slice(qualifiedSecurityPath.length)} element={component} />)}
    </Routes>
  );
};

export default Router;
