import * as React from 'react';

import QueryHelper from 'components/common/QueryHelper';

const queryExample = (
  <p>
    Find report containing critical in the description:<br />
    <kbd>pattern:critical</kbd><br />
  </p>

);

const fieldMap = {
  subtitle: 'The subtitle of the report',
  description: 'The description of the report',
};
const ReportQueryHelper = () => (
  <QueryHelper example={queryExample} entityName="report" commonFields={['title']} fieldMap={fieldMap} />
);

export default ReportQueryHelper;
