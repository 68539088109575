import { SECURITY_VIEW_ID_PREFIX } from 'security-app/routing/Routes';
import { RouterRoutes } from 'security-app/routing/Router';

const entityRoutesResolver = (id: string, type: string) => {
  if (id.startsWith(SECURITY_VIEW_ID_PREFIX) && type === 'dashboard') {
    return RouterRoutes.find(({ entityId }) => entityId === id)?.path;
  }

  return null;
};

export default entityRoutesResolver;
