import React from 'react';
import { useFormikContext } from 'formik';

import { Alert, Input } from 'components/bootstrap';
import { FormikFormGroup, FormikInput, Spinner } from 'components/common';
import type { IndexSetFormValues } from 'stores/indices/IndexSetsStore';

import useDataTiering from './hooks/useDataTiering';
import RepositoryManagement from './RepositoryManagement';
import WarmTierLicenseStatus from './WarmTierLicenseStatus';
import WarmTierUpsell from './WarmTierUpsell';

const TiersConfigurationFields = () => {
  const { readiness } = useDataTiering();
  const { values } = useFormikContext<IndexSetFormValues>();

  const validateMinDaysInHotTier = (value) => {
    const errors = [];

    if (value < 0) {
      errors.push('Negative numbers are not allowed.');
    }

    if (value > values?.data_tiering?.index_lifetime_min) {
      errors.push('Min. days in hot tier needs to be smaller than min. days in storage.');
    }

    if (errors.length > 0) {
      return errors.join(' ');
    }

    return '';
  };

  if (readiness.isLoading) return <Spinner>Fetching readiness information...</Spinner>;

  if (!readiness.data?.ready) {
    return (
      <Alert bsStyle="info" title="Cluster not ready for Warm Tier">
        Your OpenSearch cluster is not fully configured to use the Warm Tier.

        {!readiness.data?.details.supported_by_search_version && (' Your Open Search version does not support Searchable Snapshots.')}

        {!readiness.data?.details.restored_snapshots_assignable && (' Restored snapshots are not assignable.')}

        {readiness.data?.details.supported_repository_types.length === 0 && (' There are no repository locations configured.')}
      </Alert>
    );
  }

  return (
    <>
      {values?.data_tiering?.warm_tier_enabled ? <WarmTierLicenseStatus /> : <WarmTierUpsell />}
      <Input id="roles-selector-input"
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9"
             label="Warm Tier">
        <FormikInput type="checkbox"
                     id="data_tiers."
                     label="Enable"
                     name="data_tiering.warm_tier_enabled"
                     help="Enable Warm Tier and use Searchable Snapshots?" />
      </Input>
      {values?.data_tiering?.warm_tier_enabled && (
        <>
          <FormikFormGroup type="number"
                           label="Min. days in Hot Tier"
                           name="data_tiering.index_hot_lifetime_min"
                           min={0}
                           max={values?.data_tiering?.index_lifetime_min}
                           validate={validateMinDaysInHotTier}
                           help="How many days at minimum your data should be stored in the Hot Tier?"
                           required />
          <RepositoryManagement />
        </>
      )}
    </>
  );
};

export default TiersConfigurationFields;
