import * as React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components';

import { Link } from 'components/common/router';
import { isPermitted } from 'util/PermissionsMixin';
import { DataTable, NoSearchResult, PaginatedList, QueryHelper, SearchForm, HoverForHelp } from 'components/common';
import type { SearchFilterUsageInDashboardWidget } from 'search-filter/types';
import useShowRouteForEntity from 'routing/hooks/useShowRouteForEntity';
import useSearchFilterUsagesQuery from 'search-filter/hooks/useSearchFilterUsagesQuery';
import StringUtils from 'util/StringUtils';
import { FILTER_USAGE_ENTITY_TYPE } from 'search-filter/constants';
import Routes from 'routing/Routes';
import useCurrentUser from 'hooks/useCurrentUser';

const TABLE_HEADERS = ['Dashboard Name', 'Widget Name', 'Owner (Creator)', 'Capability'];
export const DEFAULT_PAGINATION = {
  per_page: 25,
  page: 1,
  count: 0,
  total: 0,
};
const DEFAULT_QUERY = {
  query: '',
  per_page: 10,
  page: 1,
  sort: 'title',
  order: 'asc',
};

const StyledPaginatedList = styled(PaginatedList)`
  .pagination {
    margin: 0;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHoverForHelp = styled((props: any) => <HoverForHelp {...props} />)`
  margin-left: 5px;
`;

const FilterUsagesInDashboardWidget = ({ id, title, widget_title: widgetTitle, owner, capability, query_id, widget_id }: SearchFilterUsageInDashboardWidget) => {
  const currentUser = useCurrentUser();
  const hasViewPermissions = isPermitted(currentUser.permissions, `view:read:${id}`);
  const actualWidgetTitle = widgetTitle || 'No widget title defined';
  const entityRoute = useShowRouteForEntity(id, 'DASHBOARD');

  return (
    <tr key={`${id}-${widget_id}`}>
      <td className="limited">
        <Title>
          {hasViewPermissions ? <Link to={entityRoute}>{title}</Link> : title}
          {capability === 'None' && (
            <StyledHoverForHelp title="Missing Permissions">
              This dashboard contains a widget which is including the search filter, but you do not have any permissions for the dashboard.
            </StyledHoverForHelp>
          )}
        </Title>
      </td>
      <td>
        <Title>
          {hasViewPermissions ? <Link to={`${Routes.dashboard_show(id)}?page=${query_id}&focusedId=${widget_id}&editing=true`}>{actualWidgetTitle}</Link> : actualWidgetTitle}
          {capability === 'None' && (
            <StyledHoverForHelp title="Missing Permissions">
              This dashboard widget is including the search filter, but you do not have any permissions for the dashboard.
            </StyledHoverForHelp>
          )}
        </Title>
      </td>
      <td>{owner}</td>
      <td>{capability}</td>
    </tr>
  );
};

type Props = {
  filterId: string
};

const FilterUsagesInDashboardWidgets = ({ filterId }: Props) => {
  const [filterQuery, setSearchFilter] = useState(DEFAULT_QUERY);
  const { data } = useSearchFilterUsagesQuery(filterId, { ...filterQuery, type: FILTER_USAGE_ENTITY_TYPE.DASHBOARD_WIDGET });

  const handlePaginationChange = useCallback((page: number) => {
    setSearchFilter((cur) => ({ ...cur, page }));
  }, [setSearchFilter]);

  const handleSearch = useCallback((newSearchQuery: string | undefined) => setSearchFilter((cur) => ({
    ...cur,
    page: DEFAULT_QUERY.page,
    query: newSearchQuery,
  })), []);
  const handleReset = useCallback(() => handleSearch(DEFAULT_QUERY.query), [handleSearch]);
  const renderOverviewItem = useCallback((entity: SearchFilterUsageInDashboardWidget) => <FilterUsagesInDashboardWidget {...entity} />, []);

  return (
    <>
      <p className="description">
        Found {data.pagination.total} dashboard {StringUtils.pluralize(data.pagination.total, 'widget', 'widgets')} which include this search filter.
      </p>

      <StyledPaginatedList activePage={filterQuery.page}
                           totalItems={data.pagination.total}
                           onChange={handlePaginationChange}>
        <DataTable className="table-hover"
                   customFilter={(
                     <SearchForm onSearch={handleSearch}
                                 queryHelpComponent={(
                                   <QueryHelper entityName="dashboard"
                                                commonFields={['id']} />
                                 )}
                                 onReset={handleReset}
                                 topMargin={0} />
                   )}
                   dataRowFormatter={renderOverviewItem}
                   noDataText={<NoSearchResult>No usages have been found.</NoSearchResult>}
                   headers={TABLE_HEADERS}
                   id="shared-entities"
                   rowClassName="no-bm"
                   rows={data.elements}
                   sortByKey="title" />
      </StyledPaginatedList>
    </>
  );
};

export default FilterUsagesInDashboardWidgets;
