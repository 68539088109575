import FailureProcessingConfig from './FailureProcessingConfig';
import FailureProcessingOverview from './FailureProcessingOverview';

export default {
  systemConfigurations: [
    {
      component: FailureProcessingConfig,
      displayName: 'Failure Processing',
      configType: 'org.graylog.plugins.failure.config.EnterpriseFailureHandlingConfiguration',
      permissions: 'indices:failures',
    },
  ],
  systemOverview: [
    {
      component: FailureProcessingOverview,
    },
  ],
};
