interface Route<T> {
  INTEGRATIONS: {
    SymantecSES: {
      [key: string]: T
    },
  },
}

const SymantecSESRoutes: Route<{ index: string }> = {
  INTEGRATIONS: {
    SymantecSES: {
      ACTIVITYAPI: {
        index: '/integrations/symantecses',
      },
    },
  },
};

const ApiRoutes: Route<string> = {
  INTEGRATIONS: {
    SymantecSES: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/symantecses/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/symantecses/checkCredentials',

    },
  },
};

const DocsRoutes: Route<string> = {
  INTEGRATIONS: {
    SymantecSES: {
      GRAYLOG_SymantecSES_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_symantecSES_ActivityLog_Input.html#graylog-symantecSES-activitylog-input',
    },
  },
};

export default SymantecSESRoutes;

export { DocsRoutes, ApiRoutes };
