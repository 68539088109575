import React from 'react';

import useLicenseStatusForSubject from 'license/hooks/useLicenseStatusForSubject';
import { Alert } from 'components/bootstrap';

const WarmTierUpsell = () => {
  const { isLoading, data, error } = useLicenseStatusForSubject('/license/enterprise');

  if (isLoading || (data && data.status?.valid) || (error && error?.status === 404)) return null;

  return (
    <Alert bsStyle="info"
           title="Upgrade to access Data Tiering
">
      <p>
        Do more with your data! Gain Warm Tiers, Archiving, and other data management features for more flexible storage options with less administration.
      </p>
    </Alert>
  );
};

export default WarmTierUpsell;
