import * as React from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import IconButton from 'components/common/IconButton';

const StyledLogViewRow = styled.div<{ $highlight: boolean }>(({ theme, $highlight }) => css`
  display: flex;
  min-width: fit-content;
  font-family: ${theme.fonts.family.monospace};
  padding: 3px 5px;
  border-bottom: 1px solid ${theme.colors.table.row.border};
  position: relative;
  /* stylelint-disable selector-max-empty-lines, indentation */
  ${$highlight && css`
    background-color: ${theme.colors.table.row.backgroundHover};
    color: ${theme.utils.contrastingColor(theme.colors.table.row.backgroundHover)};
  `}
  /* stylelint-enable selector-max-empty-lines, indentation */

  &:hover {
    background-color: ${theme.colors.table.row.backgroundHover};
    color: ${theme.utils.contrastingColor(theme.colors.table.row.backgroundHover)};
  }
`);

const StyledIconButton = styled(IconButton)(({ theme }) => css`
  position: absolute;
  right: 1px;
  top: 0;
  max-height: 100%;
  background-color: ${theme.colors.table.row.backgroundHover};
`);

type Props = {
  children: React.ReactNode,
  onShowDetails?: () => void,
  highlight: boolean,
}

const LogViewRow = ({ children, onShowDetails, highlight }: Props) => {
  const [renderIcon, setRenderIcon] = useState(false);

  return (
    <StyledLogViewRow $highlight={highlight} onMouseEnter={() => setRenderIcon(true)} onMouseLeave={() => setRenderIcon(false)}>
      {children}
      {renderIcon && (
        <StyledIconButton title="Open Message Details" name="open_in_full" onClick={onShowDetails} />
      )}
    </StyledLogViewRow>
  );
};

LogViewRow.defaultProps = {
  onShowDetails: undefined,
};

export default LogViewRow;
