import * as React from 'react';

import { useGetStatuses } from 'security-app/hooks/useInvestigationsAPI';
import { Spinner } from 'components/common';

type Props = {
  statusName: string
}

const StatusValue = ({ statusName }: Props) => {
  const { statuses, loadingStatuses } = useGetStatuses();

  if (loadingStatuses) {
    return <Spinner />;
  }

  const status = statuses.find(({ status: statName }) => statusName === statName);

  if (status) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{status.status}</>;
  }

  return <>unknown</>;
};

export default StatusValue;
