import * as React from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon, ProgressBar, RelativeTime, ConfirmDialog } from 'components/common';
import { Badge, Button } from 'components/bootstrap';

import { JobStatus, type InstantArchiveJob, JobType } from '../Types';

type Props = {
  job: InstantArchiveJob,
  onCancel: (id: string) => Promise<void>,
  onAcknowledge: (id: string) => Promise<void>,
};

const StatusBadge = styled(Badge)<{ status: string }>(({ status, theme }) => {
  const {
    primary,
    success,
    info,
    warning,
    danger,
  } = theme.colors.variant.dark;

  const statuses = {
    cancelled: warning,
    complete: success,
    error: danger,
    queued: info,
    running: primary,
  };

  const color = statuses[status] ?? info;

  return css`
    margin-left: 4px;
    background-color: ${color};
    color: ${theme.utils.readableColor(color)};
  `;
});

const StyledIcon = styled(Icon)`
  margin-right: 2px;
`;

const StyledProgressBar = styled(ProgressBar)(({ theme }) => `
  margin-top: ${theme.spacings.xs};
`);

const AcknowledgeButton = styled(Button)(({ theme }) => css`
  && {
    color: ${theme.colors.variant.light.default};

    &:hover {
      color: ${theme.colors.variant.default};
    }
  }
`);

const JobWrapper = styled.div(({ theme }) => css`
  margin-bottom: ${theme.spacings.xs};

  &:hover {
    background-color: ${theme.colors.variant.lighter.default};
  }
`);

const getJobActionDescription = (job: InstantArchiveJob) => (
  job.job_type === JobType.Restore
    ? <span>Restore operation on archive <code>{job.archive.join(', ')}</code> started </span>
    : <span>Deleting archives in <code>{job.archive.join(', ')}</code> started </span>
);

const Job = ({ job, onCancel, onAcknowledge }: Props) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const jobIsOver = job.status === JobStatus.Complete || job.status === JobStatus.Cancelled
    || job.status === JobStatus.Error;
  const jobStatus = job.status === JobStatus.Runnable ? 'queued' : job.status;

  const handleCancel = async () => {
    await onCancel(job.id);
    setShowConfirmDialog(false);
  };

  return (
    <JobWrapper>
      <StyledIcon name="settings" />
      {getJobActionDescription(job)}{' '}
      <RelativeTime dateTime={job.started_at} />
      <StatusBadge status={job.status}>{jobStatus}</StatusBadge>
      {!jobIsOver
      && job.is_cancelable
        ? (
          <Button type="button"
                  bsSize="xs"
                  bsStyle="primary"
                  className="pull-right"
                  onClick={() => setShowConfirmDialog(true)}>Cancel
          </Button>
        ) : (
          <AcknowledgeButton type="button"
                             bsStyle="link"
                             onClick={() => onAcknowledge(job.id)}
                             bsSize="xs"
                             className="pull-right"
                             title="Acknowledge"><Icon name="close" />
          </AcknowledgeButton>
        )}

      {!jobIsOver && <StyledProgressBar bars={[{ value: job.percent_done, bsStyle: 'info', animated: true }]} />}
      {showConfirmDialog && (
        <ConfirmDialog show={showConfirmDialog}
                       onConfirm={handleCancel}
                       onCancel={() => setShowConfirmDialog(false)}
                       title="Cancel Instant archives job">
          <p>
            You are about to cancel an instant archiving {job.job_type} job.
            This action cannot be undone.
          </p>
          <p>Are you sure you want to continue?</p>
        </ConfirmDialog>
      )}
    </JobWrapper>
  );
};

export default Job;
