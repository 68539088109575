import * as React from 'react';
import { useMemo } from 'react';

import type { StatusAPIType } from 'security-app/hooks/api/investigationsAPI.types';
import { useGetStatuses } from 'security-app/hooks/useInvestigationsAPI';
import { Spinner } from 'components/common';
import FilterValueSelect from 'views/components/widgets/overview-configuration/filters/FilterValueSelect';

type Props = {
  value: string | undefined,
  onChange: (newValue: string) => void,
  selectedStatuses: Array<string>
}

const StatusFilter = ({ value, onChange, selectedStatuses }: Props) => {
  const { statuses, loadingStatuses } = useGetStatuses();
  const statusOptions = useMemo(() => (
    statuses.map((status: StatusAPIType) => ({
      value: status.status,
      label: status.status,
      disabled: selectedStatuses.includes(status.id),
    }))
  ), [selectedStatuses, statuses]);

  if (loadingStatuses) {
    return <Spinner />;
  }

  return (
    <FilterValueSelect value={value}
                       onChange={onChange}
                       placeholder="Select a status"
                       options={statusOptions} />
  );
};

export default StatusFilter;
