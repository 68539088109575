import fetch, { fetchPeriodically } from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import Store from 'logic/local-storage/Store';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import type {
  Bundle,
  Bundles,
  HubBundleType,
  IlluminateStatus,
  NewBundleType,
  HubReleaseNotes,
  InstallStatus,
} from 'illuminate/types';

export async function getInstalledBundles(): Promise<Bundles> {
  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.index().url));
}

export async function getBundlesStatus(): Promise<IlluminateStatus> {
  return fetchPeriodically('GET', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.status().url));
}

type UpdateBundleType = {
  bundleId: string,
  bundleData: Partial<Bundle>,
};

export async function updateBundle({ bundleId, bundleData }: UpdateBundleType): Promise<Bundle> {
  return fetch('POST', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.one(bundleId).url), bundleData);
}

export async function deleteBundle({ bundleId }: { bundleId: string }): Promise<void | Bundle> {
  return fetch('DELETE', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.one(bundleId).url));
}

export type UploadBundleType = {
  bundleFile: File,
  nextPercentage?: (percentage: number) => void,
};

export async function uploadBundle({ bundleFile, nextPercentage }: UploadBundleType): Promise<string> {
  const formData = new FormData();
  const http = new XMLHttpRequest();

  formData.append('file', bundleFile);

  return new Promise<string>((resolve, reject) => {
    http.upload.onprogress = ({ loaded, total }) => {
      const percent = Math.round((loaded * 100) / total);
      nextPercentage(percent);
    };

    http.onreadystatechange = () => {
      if (http.readyState === 4) {
        if (http.status === 200) {
          resolve(http.responseText);
        } else {
          const errorMessage = JSON.parse(http.responseText)?.message ?? http.statusText;
          reject(new Error(errorMessage));
        }
      }
    };

    http.open('POST', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.upload().url), true);

    http.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    http.setRequestHeader('X-Requested-By', 'XMLHttpRequest');
    http.setRequestHeader('Access-Control-Allow-Origin', '*');

    http.send(formData);
  });
}

/* Illuminate HUB */
export async function getLatestBundle(): Promise<NewBundleType> {
  return new Promise((resolve: (data: NewBundleType) => void, reject: (error: Error) => void) => {
    fetch('GET', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.hub.latest().url))
      .then((data: NewBundleType) => {
        const skipedVersion = Store.get('skiped-bundle-version');

        resolve({
          ...data,
          is_new_version: data.is_new_version && skipedVersion !== data.version,
        });
      })
      .catch((error: Error) => reject(error));
  });
}

export async function getHubBundles(): Promise<{ bundles: HubBundleType[] }> {
  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.hub.index().url));
}

export async function getReleaseNotes(version: string): Promise<HubReleaseNotes> {
  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.hub.releaseNotes(version).url));
}

export async function installHubBundle({ bundleId }: { bundleId: string }): Promise<string> {
  const http = new XMLHttpRequest();

  return new Promise<string>((resolve, reject) => {
    http.onreadystatechange = () => {
      if (http.readyState === 4) {
        if (http.status === 200) {
          resolve(http.responseText);
        } else {
          const errorMessage = JSON.parse(http.responseText)?.message ?? http.statusText;
          reject(new Error(errorMessage));
        }
      }
    };

    http.open('POST', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.hub.install(bundleId).url), true);

    http.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    http.setRequestHeader('X-Requested-By', 'XMLHttpRequest');
    http.setRequestHeader('Access-Control-Allow-Origin', '*');

    http.send();
  });
}

export const getInstallationStatus = async (): Promise<InstallStatus> => fetch('GET', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.installStatus().url));
