import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DataTable, Icon, PaginatedList, Spinner, Timestamp } from 'components/common';
import usePaginationQueryParameter from 'hooks/usePaginationQueryParameter';
import { sortByDate } from 'util/SortUtils';

import ReportsActions from '../ReportsActions';

const StatusColumn = styled.td`
  text-align: center;
  width: 25px;
`;

const DateColum = styled.td`
  width: 300px;
`;

type HistoryItem = {
  status: string,
  message: string,
  generated_at: string,
}

const HistoryItemRow = ({ status, message, generated_at: generatedAt }: HistoryItem, idx: string) => {
  const icon = status === 'SUCCESS' ? 'check_circle' : 'cancel';
  const className = status === 'SUCCESS' ? 'text-success' : 'text-danger';

  return (
    <tr key={idx}>
      <StatusColumn><Icon name={icon} className={className} /></StatusColumn>
      <td>{message}</td>
      <DateColum><Timestamp dateTime={generatedAt} /></DateColum>
    </tr>
  );
};

const HeaderCell = (header: React.ReactNode) => <th>{header}</th>;

const useReportHistory = (reportId: string, reportTitle: string, pagination: { currentPage: number, pageSize: number }) => {
  const [{ history, total }, setHistoryResponse] = useState<{
    history: Array<HistoryItem> | undefined,
    total: number
  }>({ history: undefined, total: 0 });

  useEffect(() => {
    ReportsActions.getHistory(reportId, reportTitle, (pagination.currentPage - 1) * pagination.pageSize, pagination.pageSize)
      .then(setHistoryResponse);
  }, [reportId, reportTitle, pagination]);

  return { history, total };
};

const ReportHistory = ({ reportId, reportTitle }: { reportId: string, reportTitle: string }) => {
  const { page: currentPage, pageSize } = usePaginationQueryParameter();

  const { history, total: totalHistoryItems } = useReportHistory(reportId, reportTitle, { currentPage, pageSize });

  if (!history) {
    return <Spinner text="Loading report history..." />;
  }

  const headers = ['Status', 'Message', 'Date'];
  const sortedHistory = history.sort((h1, h2) => sortByDate(h1.generated_at, h2.generated_at, 'desc'));

  return (
    <PaginatedList totalItems={totalHistoryItems}>
      <DataTable id="report-history"
                 className="table-hover"
                 headers={headers}
                 headerCellFormatter={HeaderCell}
                 rows={sortedHistory}
                 dataRowFormatter={HistoryItemRow}
                 noDataText="There are no records of this report being sent."
                 filterLabel=""
                 filterKeys={[]} />
    </PaginatedList>
  );
};

ReportHistory.propTypes = {
  reportId: PropTypes.string.isRequired,
};

export default ReportHistory;
