import * as React from 'react';

import type { PriorityAPIType } from 'security-app/hooks/api/investigationsAPI.types';
import { useGetPriorities } from 'security-app/hooks/useInvestigationsAPI';
import { Spinner } from 'components/common';
import FilterValueSelect from 'views/components/widgets/overview-configuration/filters/FilterValueSelect';

type Props = {
  value: string | undefined,
  onChange: (newValue: string) => void,
  selectedPriorities: Array<string>
}

const StatusFilter = ({ value, onChange, selectedPriorities }: Props) => {
  const { priorities, loadingPriorities } = useGetPriorities();
  const priorityOptions = React.useMemo(() => (
    priorities.map((priority: PriorityAPIType) => ({
      value: String(priority.priority),
      label: priority.text,
      disabled: selectedPriorities.includes(priority.id),
    }))
      .sort((a: { value: string; }, b: { value: string; }) => +a.value - +b.value)
  ), [priorities, selectedPriorities]);

  if (loadingPriorities) {
    return <Spinner />;
  }

  return (
    <FilterValueSelect value={value}
                       onChange={onChange}
                       placeholder="Select a priority"
                       options={priorityOptions} />
  );
};

export default StatusFilter;
