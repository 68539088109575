import TiersConfigurationFields from './TiersConfigurationFields';
import TiersSummary from './TiersSummary';

export default {
  dataTiering: {
    type: 'hot_warm',
    TiersConfigurationFields,
    TiersSummary,
  },
};
