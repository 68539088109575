import React from 'react';

import { SecurityLayout } from 'security-app/components/Layout';
import Router from 'security-app/routing/Router';

const SecurityPage = () => (
  <SecurityLayout>
    <Router />
  </SecurityLayout>
);

export default SecurityPage;
