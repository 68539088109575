import React from 'react';
import type { SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';

import { Button, Col, Label, Row } from 'components/bootstrap';

type Props = {
  repository: {
    value: string,
    label: string,
    type: string,
    ready: boolean,
    description?: string
  }
  onDelete: (event: SyntheticEvent, value: string) => void,
  isSelected: boolean,
}

const RepositoryLabel = styled(Label)(({ theme }) => css`
  margin-left: ${theme.spacings.xs};
`);

const RepositoryDescription = styled.p<{ $isSelected: boolean }>(({ theme, $isSelected }) => css`
  margin-bottom: 0;
  margin-top: ${theme.spacings.xs};
  font-size: ${theme.fonts.size.small};
  color: ${$isSelected ? theme.colors.variant.lightest.gray : theme.colors.variant.darker.gray};
`);

const RepositoryOption = ({ repository, onDelete, isSelected }: Props) => (
  <Row>
    <Col md={8}>
      <Row>
        <Col md={12}>
          {repository.label}
          <RepositoryLabel>{repository.type}</RepositoryLabel>
          <RepositoryLabel bsStyle={repository.ready ? 'success' : 'danger'}>{repository.ready ? 'Ready' : 'Not Ready'}</RepositoryLabel>
        </Col>
      </Row>
      {repository.description && (
      <Row>
        <Col md={12}><RepositoryDescription $isSelected={isSelected}>{repository.description}</RepositoryDescription></Col>
      </Row>
      )}
    </Col>
    <Col md={4} className="text-right">
      <Button bsSize="xs" type="button" onClick={(event) => onDelete(event, repository.value)}>
        Delete
      </Button>
    </Col>
  </Row>
);

export default RepositoryOption;
