/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import * as React from 'react';

import DateFilter from 'views/components/widgets/overview-configuration/filters/DateFilter';
import type { FilterComponents } from 'views/components/widgets/overview-configuration/filters/types';
import AssigneeFilter from 'search/overview-configuration/filters/AssigneeFilter';
import StatusFilter from 'search/events/filters/StatusFilter';
import Status from 'search/events/Status';
import Assignee from 'search/overview-configuration/Assignee';

const dateFilterComponents = {
  configuration: (_selectedValues, editValue: Array<string>, onChange: (newValue: Array<string>, shouldSubmit: boolean) => void) => (
    <DateFilter values={editValue} onChange={(newValue) => onChange(newValue, false)} />
  ),
  renderValue: (values: string) => values.replace(',', ' to '),
  valueForConfig: (values: Array<string>) => values.join(','),
  valueFromConfig: (value: string) => (value ? value.split(',') : []),
  submitChangesOnClose: true,
};

const filterComponents: FilterComponents = [
  {
    attribute: 'opened_at',
    ...dateFilterComponents,
  },
  {
    attribute: 'updated_at',
    ...dateFilterComponents,
  },
  {
    attribute: 'closed_at',
    ...dateFilterComponents,
  },
  {
    configuration: (selectedValues, editValue: string, onChange: (newValue: string) => void) => (
      <AssigneeFilter value={editValue}
                      selectedAssignees={selectedValues}
                      onSelect={(newValue) => onChange(newValue)} />
    ),
    attribute: 'owner',
    renderValue: (value) => <Assignee assigneeId={value} />,
    allowMultipleValues: true,
  },
  {
    configuration: (selectedValues, editValue: string, onChange: (newValue: string) => void) => (
      <StatusFilter value={editValue} onSelect={onChange} selectedValues={selectedValues} />
    ),
    attribute: 'status',
    renderValue: (value) => <Status statusId={value} />,
    allowMultipleValues: true,
  },
];

export default filterComponents;
