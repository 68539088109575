import * as React from 'react';

import ViewProvider from 'search-filter/contexts/ViewProvider';
import ParametersProvider from 'search-filter/contexts/ParametersProvider';
import HandlerContextProvider from 'search-filter/contexts/HandlerContextProvider';
import SearchFilterBar from 'search-filter/components/search-filter-bar/SearchFilterBar';

const SearchPageSearchFilterBar = () => (
  <ViewProvider>
    <ParametersProvider>
      <HandlerContextProvider>
        <SearchFilterBar />
      </HandlerContextProvider>
    </ParametersProvider>
  </ViewProvider>
);

export default SearchPageSearchFilterBar;
