import * as React from 'react';

import { useGetPriorities } from 'security-app/hooks/useInvestigationsAPI';
import { Spinner } from 'components/common';

type Props = {
  priorityId: string
}

const PriorityValue = ({ priorityId }: Props) => {
  const { priorities, loadingPriorities } = useGetPriorities();

  if (loadingPriorities) {
    return <Spinner />;
  }

  const priority = priorities.find(({ id }) => priorityId === id);

  if (priority) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{priority.text}</>;
  }

  return <>unknown</>;
};

export default PriorityValue;
